import { FC } from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch } from 'react-redux';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';

import {
  StyledPaper,
  IconWrapper,
  ContentRow,
  FormButton,
} from 'pages/Dashboard/components/Achievements/style';
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import { toggleUpdateSpeechHistory } from 'pages/Modals/ducks/slice';

const ReadOnlyField = ({ label, value }: any) => (
  <Typography variant='body1'>
    {label}: {value}
  </Typography>
);

const SpeechHistory: FC<any> = ({ data, isMemberProfilePage }) => {
  const dispatch = useDispatch();
  const user = getAuthenticatedUser();

  const { displayName, speechLogs, id: courseId } = data;

  const hadleOpenModal = (history: any) => {
    dispatch(
      toggleUpdateSpeechHistory({
        ...history,
        displayName,
        course: courseId,
        learner: user.userId,
        club: history.club.id,
        evaluator: history.evaluator.id,
      }),
    );
  };

  return (
    <StyledPaper sx={{ my: 2, p: 5 }}>
      <Typography variant='h4'>{displayName}</Typography>
      {speechLogs.map((history: any) => (
        <ContentRow>
          <IconWrapper>
            <MicRoundedIcon />
          </IconWrapper>
          <Typography variant='h4'>{history.speechTitle}</Typography>
          <Grid container>
            <Grid
              container
              item
              xs={11}
              columnSpacing={2}
              sx={{ justifyContent: 'center' }}
            >
              <Grid item xs={12} sm={4}>
                <ReadOnlyField label='Club' value={history.club.name} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ReadOnlyField
                  label='Evaluator'
                  value={history.evaluator.name}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ReadOnlyField
                  label='Date'
                  value={
                    history.speechDate &&
                    dayjs(history.speechDate)?.format('MMMM DD, YYYY')
                  }
                />
              </Grid>
            </Grid>
            <Grid container item xs={1} sx={{ justifyContent: 'center' }}>
              {!isMemberProfilePage && (
                <FormButton
                  aria-label={`Edit ${history.speechTitle}`}
                  onClick={() => hadleOpenModal(history)}
                >
                  <EditIcon />
                </FormButton>
              )}
            </Grid>
          </Grid>
        </ContentRow>
      ))}
    </StyledPaper>
  );
};

export default SpeechHistory;
