import { FunctionComponent, useState } from 'react';
import {
  Avatar,
  Typography,
  Box,
  ListItemText,
  ListItemAvatar,
  Stack,
} from '@mui/material';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';

import Button from 'elements/Button';
import dummyImage from 'assets/images/dummyImage.png';
import { REQUEST_TYPES, REQUEST_STATUS, APPROVAL_REQUEST_STATUS } from 'config';

import { RequestList, RequestListItem } from './style';
import { handleOpenRequestModal } from 'pages/Requests/ducks/approvalSlice';
import { getPlatformName, getUserName } from 'utils/utility';
import { HOC } from 'elements/Modal';
import DenyDetails from './DenyDetails';

const DenyDetailsModal = HOC(DenyDetails);

const normalizeRequests = (request: any, user: any) => {
  if (request.requestType === REQUEST_TYPES.EVALUATION) {
    return {
      ...request,
      requesterName: getUserName(request.speechLog.learner),
      requestDescription: request.description || (
        <>
          {' '}
          Please rate my <span>
            {' '}
            {request.speechLog.speech_title}{' '}
          </span> speech{' '}
        </>
      ),
      requesterProfileImage: request.requester.profileImage,
    };
  }

  const isAuthUserRequestApprover = user.userId === request.approvedBy;
  const isRequestApprovedOrDenied = [
    REQUEST_STATUS.DENIED,
    REQUEST_STATUS.APPROVED,
  ].includes(request.status);

  return {
    ...request,
    requestDescription: (
      <>
        {!isAuthUserRequestApprover && isRequestApprovedOrDenied
          ? `Action no longer needed: ${
              request.approverName
            } ${request.status.toLowerCase()} ${request.requesterName}'s ${
              request.courseDisplayName
            } ${
              request.type === 'Level' ? `Level ${request.levelNumber}` : 'path'
            } completion request on ${dayjs(request.created)?.format(
              'MMMM DD',
            )}.`
          : `Review and approve/deny my ${request.courseDisplayName} ${
              request.type === 'Level' ? `Level ${request.levelNumber}` : 'path'
            } completion request.`}
      </>
    ),
  };
};

const getButtonText = (
  requestType: any,
  status: any,
  isClubChanged = false,
) => {
  if (requestType === REQUEST_TYPES.EVALUATION) {
    if (status === REQUEST_STATUS.PENDING) {
      return 'Open';
    }

    return 'Submitted';
  }

  if (isClubChanged) {
    return 'Credit changed';
  }

  return APPROVAL_REQUEST_STATUS[status];
};

const RequestItem: FunctionComponent<any> = ({ request }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = getAuthenticatedUser();

  const [denyDetailsModalOpen, setDenyDetailsModalOpen] = useState(false);
  const toggleDenyDetailsModal = () => setDenyDetailsModalOpen(prev => !prev);

  const {
    requesterName,
    created,
    requesterProfileImage,
    requestType,
    status,
    requestDescription,
    reason,
    comment,
    isShifted,
  } = normalizeRequests(request, user);
  const buttonText = getButtonText(requestType, status, isShifted);

  const handleClickRequest = () => {
    if (requestType === REQUEST_TYPES.APPROVAL) {
      dispatch(handleOpenRequestModal(request));
      return;
    }

    history.push({
      pathname: `${getPlatformName()}/feedback/evaluation-form/${request.id}`,
      search: '?type=requested',
    });
  };

  return (
    <RequestList>
      <RequestListItem disableGutters disablePadding>
        <ListItemAvatar>
          <Avatar
            alt='User image'
            src={requesterProfileImage?.small || dummyImage}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <Box>
                <Typography
                  variant='h4'
                  component='span'
                  className='fw-600'
                  textTransform='capitalize'
                >
                  {requesterName}
                </Typography>{' '}
                - {dayjs(created)?.format('MMMM DD, YYYY')}
              </Box>
              <Box>
                {requestType === REQUEST_TYPES.EVALUATION ? (
                  <Typography variant='h4' component='span'>
                    {'Evaluation Feedback Request... '}
                  </Typography>
                ) : null}
                {requestDescription}
              </Box>
            </>
          }
          sx={{ paddingRight: 2 }}
        />
        {status === REQUEST_STATUS.PENDING ? (
          <Stack flex='0 0 10em' alignItems='center'>
            {isShifted ? (
              <>
                <Typography variant='h4'>{buttonText}</Typography>
                <Typography variant='body2' sx={{ fontStyle: 'italic' }}>
                  to a different club
                </Typography>
              </>
            ) : (
              <Button
                disabled={status !== REQUEST_STATUS.PENDING}
                onClick={handleClickRequest}
                variant='outlined'
              >
                {buttonText}
              </Button>
            )}
          </Stack>
        ) : (
          <Stack alignItems='center' flex='0 0 10em'>
            <Typography variant='h4'>{buttonText}</Typography>
            {isShifted ? (
              <Typography variant='body2' sx={{ fontStyle: 'italic' }}>
                to a different club
              </Typography>
            ) : null}
            {status === APPROVAL_REQUEST_STATUS.Reversed ? (
              <Typography variant='body2' sx={{ fontStyle: 'italic' }}>
                by World Headquarters
              </Typography>
            ) : null}
            {status === APPROVAL_REQUEST_STATUS.Denied ? (
              <Box mt={2}>
                <Button onClick={toggleDenyDetailsModal} variant='outlined'>
                  View Details
                </Button>
                {denyDetailsModalOpen ? (
                  <DenyDetailsModal
                    title='Denial Details'
                    handleClose={toggleDenyDetailsModal}
                    reason={reason}
                    comment={comment}
                  />
                ) : null}
              </Box>
            ) : null}
          </Stack>
        )}
      </RequestListItem>
    </RequestList>
  );
};

export default RequestItem;
