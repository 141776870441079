import React, { FC, useState, useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { capitalize } from 'lodash';

import { Box, Stack, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import SpeechcraftersTable from './Components/SpeechcraftersTable';
import TextField from 'elements/Form/TextField';

import { useGetEventAttendeesQuery } from 'api/speechCraftApi';
import Loader from 'elements/Loader';
import { getUserName } from 'utils/utility';
import { DashboardRoutes as Routes } from 'routes';

import { SearchForm } from 'pages/SpeechDashboard/Components/SpeechcraftLearning/style';

const Speechcrafter: FC<any> = () => {
  const [searchName, setSearchName] = useState('');
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);

  const { data, isLoading } = useGetEventAttendeesQuery({});

  useEffect(() => {
    const rows =
      data?.attendees?.map((user: any) => ({
        profileName: getUserName(user),
        profileUrl: generatePath(Routes.SPEECHCRAFTER_PROFILE.path, {
          username: user.username,
          activeSection: 'about',
        }),
      })) || [];

    setRows(rows);
    setFilteredRows(rows);
  }, [data?.attendees]);

  useEffect(() => {
    if (filter) {
      setFilteredRows((prevRows: any) =>
        prevRows.filter((row: any) =>
          capitalize(row.profileName).includes(capitalize(filter)),
        ),
      );
    }
  }, [filter]);

  return (
    <Box>
      {isLoading ? (
        <Box mt={10}>
          <Loader />
        </Box>
      ) : (
        <>
          <Stack direction='row' justifyContent='flex-end' alignItems='center'>
            <SearchForm>
              <TextField
                variant='standard'
                placeholder='Search'
                value={searchName}
                onChange={(event: any) => {
                  const { value } = event.target;
                  setSearchName(value);

                  if (!value) {
                    setFilter('');
                    setFilteredRows(rows);
                  }
                }}
                onKeyPress={(event: any) => {
                  if (event.key === 'Enter') {
                    const { value } = event.target;
                    setSearchName(value);
                    setFilter(value);

                    if (!value) {
                      setFilter('');
                      setFilteredRows(rows);
                    }
                  }
                }}
              />
              <IconButton aria-label='Search' onClick={() => setFilter(searchName)}>
                <SearchIcon />
              </IconButton>
            </SearchForm>
          </Stack>
          <SpeechcraftersTable rows={filteredRows} />
        </>
      )}
    </Box>
  );
};


export default Speechcrafter;
