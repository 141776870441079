import { Box, styled } from '@mui/material';
import { theme } from 'styles/theme';
import { DeviceUp } from 'styles/breakpoints';

export const DashoardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

export const SideContainer = styled('div')`
  padding: 40px 40px 20px 20px;
  min-height: 10px;
  flex-grow: 1;
  order: 2;
  max-width: 300px;
  min-width: 300px;
`;

export const MainContainer = styled('div')`
  flex-grow: 8;
  order: 1;
`;

export const GreetUser = styled('div')`
  background: ${({ theme }) => theme.palette.ternary.white};
  padding: 35px 15px;
  text-align: center;

  & > h2 {
    max-width: 600px;
    width: 100%;
    margin: 0 auto 5px;

    @media ${DeviceUp.md} {
      font-size: 32px;
    }

    & span {
      text-transform: capitalize;
    }
  }
`;

export const TabLinksWrapper = styled('div')(({ theme }) => {
  return `
  background: ${theme.palette.ternary.main};
  background: linear-gradient(180deg, ${theme.palette.ternary.light} -50.33%, ${theme.palette.ternary.main} 100%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1200;

  & > .MuiBox-root {
    max-width: 320px;

    @media ${DeviceUp.sm} {
      max-width: inherit;
    }
  }

  & .MuiTab-root {
    font-weight: 600;
    text-transform: none;
    color: ${theme.palette.ternary.greyShade};
    font-family: ${theme.typography.fontFamily} !important;

    @media ${DeviceUp.xl} {
      font-size: 18px;
    }

    &:hover {
      color: ${theme.palette.primary.main};
    }

    &.Mui-selected {
      color: ${theme.palette.ternary.greyShade};
    }

    &:focus-visible {
      outline-offset: -3px;
    }
  }

  & .MuiTabs-indicator {
    bottom: 4px;
    height: 4px;
    display: flex;
    justify-content: center;
    background-color: transparent;
  
    & .MuiTabs-indicatorSpan {
      width: 70%;
      background-color: ${theme.palette.primary.main};
    }
  }
`;
});

export const TabPanelsWrapper = styled('div')`
  max-width: 1140px;
  width: 100%;
  padding: 0 15px;
  margin: 40px auto 0;

  & h2 {
    margin-bottom: 10px;
  }
`;

export const HijackingAlert = styled('div')`
  max-width: 950px;
  margin: 5px auto;
  padding: 0 8px;

  .MuiPaper-root {
    align-items: center;
  }

  .MuiAlert-icon {
    align-self: flex-start;
  }
  .MuiAlert-message {
    padding: 0;
  }

  .MuiAlert-action {
    align-self: flex-start;
    padding-top: 1px;

    .MuiButton-root {
      min-width: auto;
      min-height: auto;
    }
  }
`;
