import React, { FunctionComponent, useMemo, useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import { HOC } from 'elements/Modal';
import { Link as MuiLink } from '@mui/material';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';

import Loader from 'elements/Loader';
import CourseSection from 'pages/Dashboard/components/CourseSection';

import { ReactComponent as IntroIcon } from 'assets/images/introIcon.svg';
import { ReactComponent as SocialIcon } from 'assets/images/socialIcon.svg';
import { ReactComponent as InterestsIcon } from 'assets/images/interestsIcon.svg';
import { ReactComponent as TwitterXIcon } from 'assets/images/icons/TwitterX.svg';
import { ReactComponent as FacebookIcon } from 'assets/images/icons/facebook.svg';
import { ReactComponent as LinkedinIcon } from 'assets/images/icons/linkedin.svg';
import { ReactComponent as InstagramIcon } from 'assets/images/icons/instagram.svg';

import { getActiveCoursesSelector } from 'pages/Dashboard/ducks/selectors';
import { useAppContext } from 'contexts/AppContext';
import useProfilePage from '../useProfilePage';

import { HeadingWrapper, InfoSection, SocialMediaIcons } from './style';
import { toNumber } from 'lodash';
import EditField from './EditField';
import MeetingLogs from './MeetingLogs';
import useRoles from 'hooks/useRoles';
import { useIsSpeechCraftMFE } from 'hooks';
import { Link } from 'react-router-dom';
import SpeechcrafterContactEdit from './SpeechcrafterContactEdit';

const iconSize = 20;
const commonIconStyles = {
  borderRadius: 8,
};

const EditFieldModal = HOC(EditField);
const SpeechcrafterContactEditModal = HOC(SpeechcrafterContactEdit);

const About: FunctionComponent<any> = ({ aboutData = {} }) => {
  const {
    intro,
    interest,
    facebookUrl,
    instagramUrl,
    linkedinUrl,
    twitterUrl,
    phoneNumber,
    contactEmail,
    user: profileUser,
  } = aboutData;

  const authUser = getAuthenticatedUser();
  const { isSpeechcrafter, isCoordinator } = useRoles();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();

  const showEditButton =
    profileUser?.id === authUser.userId && (isSpeechcrafter || isCoordinator);

  const isSpeechcrafterContactInfoPresent =
    phoneNumber || contactEmail || intro;

  const [editModalData, setEditModalData] = useState({
    isModalOpen: false,
    modalTitle: '',
    fieldTitle: '',
    initialValue: '',
  });
  const handleOpenEditModal = (
    modalTitle: string,
    fieldTitle: string,
    initialValue: string,
  ) => {
    setEditModalData({
      isModalOpen: true,
      modalTitle,
      fieldTitle,
      initialValue,
    });
  };
  const handleCloseEditModal = () => {
    setEditModalData({
      isModalOpen: false,
      modalTitle: '',
      fieldTitle: '',
      initialValue: '',
    });
  };

  const [
    isSpeechcrafterContactEditModalOpen,
    setIsSpeechcrafterContactEditModalOpen,
  ] = useState(false);
  const handleOpenSpeechcrafterContactEditModal = () =>
    setIsSpeechcrafterContactEditModalOpen(true);
  const handleCloseSpeechcrafterContactEditModal = () =>
    setIsSpeechcrafterContactEditModalOpen(false);

  const { isLoading: isActiveCourseLoading, data: activeEnrolledCourses } =
    useSelector(getActiveCoursesSelector);

  const { roles } = useAppContext();
  const { isMemberProfilePage, clubId, isSelfProfilePage } = useProfilePage();

  const canViewCourseProgress = useMemo(() => {
    if (!isMemberProfilePage && !isSelfProfilePage) {
      return false;
    }

    if (isSelfProfilePage) {
      return true;
    }

    return roles.some((club: any) => {
      return (
        club.id === toNumber(clubId) &&
        club.roles?.some(({ isBcm }: any) => isBcm)
      );
    });
  }, [clubId, isMemberProfilePage, isSelfProfilePage, roles]);

  return (
    <Box>
      <Box mb={10}>
        <Typography mb={8}>
          Edit your Introduction, Interests, and Social Media Handles on your{' '}
          <Link
            to={{ pathname: 'https://www.toastmasters.org/' }}
            target='_blank'
          >
            {' '}
            toastmasters.org
          </Link>{' '}
          profile.
        </Typography>
        {!isSpeechCraftMFE ? (
          <InfoSection>
            <HeadingWrapper>
              <IntroIcon />{' '}
              <Typography variant='h3' component='span' m={0}>
                Introduction
              </Typography>
            </HeadingWrapper>
            <Typography variant='body1' fontStyle={intro ? 'normal' : 'italic'}>
              {intro}
            </Typography>
          </InfoSection>
        ) : null}
        {isSpeechCraftMFE ? (
          <InfoSection>
            <HeadingWrapper>
              <IntroIcon />{' '}
              <Typography variant='h3' component='span' m={0}>
                My Contact Information
              </Typography>
              {showEditButton ? (
                <Box ml='auto'>
                  <IconButton
                    aria-label='edit intro'
                    color='secondary'
                    sx={{ '&:hover': { borderRadius: '4px' } }}
                    size='small'
                    onClick={handleOpenSpeechcrafterContactEditModal}
                  >
                    <EditIcon sx={{ mr: '0 !important' }} />
                  </IconButton>
                </Box>
              ) : null}
            </HeadingWrapper>
            <Typography
              variant='body1'
              fontStyle={
                isSpeechcrafterContactInfoPresent ? 'normal' : 'italic'
              }
            >
              {!isSpeechcrafterContactInfoPresent ? (
                'Share any contact information (for example, cell phone number or preferred email address) you would like to provide for others in your Speechcraft event.'
              ) : (
                <>
                  {phoneNumber ? (
                    <Typography fontWeight='bold' mb={1}>
                      Phone Number:{' '}
                      <Typography fontWeight='normal' component='span'>
                        <MuiLink href={`tel:${phoneNumber}`}>
                          {phoneNumber}
                        </MuiLink>
                      </Typography>
                    </Typography>
                  ) : null}
                  {contactEmail ? (
                    <Typography fontWeight='bold' mb={1}>
                      Email:{' '}
                      <Typography fontWeight='normal' component='span'>
                        <MuiLink href={`mailto:${contactEmail}`}>
                          {contactEmail}
                        </MuiLink>
                      </Typography>
                    </Typography>
                  ) : null}
                  {intro ? (
                    <Typography fontWeight='bold' mb={1}>
                      Additional Information:{' '}
                      <Typography fontWeight='normal' component='span'>
                        {intro}
                      </Typography>
                    </Typography>
                  ) : null}
                </>
              )}
            </Typography>
          </InfoSection>
        ) : null}
        <InfoSection>
          <HeadingWrapper>
            <InterestsIcon />{' '}
            <Typography variant='h3' component='span' m={0}>
              {isSpeechCraftMFE ? 'About Me' : 'Interests'}
            </Typography>
            {showEditButton ? (
              <Box ml='auto'>
                <IconButton
                  aria-label='Edit About Me'
                  color='secondary'
                  sx={{ '&:hover': { borderRadius: '4px' } }}
                  size='small'
                  onClick={() =>
                    handleOpenEditModal('About Me', 'interest', interest)
                  }
                >
                  <EditIcon sx={{ mr: '0 !important' }} />
                </IconButton>
              </Box>
            ) : null}
          </HeadingWrapper>
          <Typography
            variant='body1'
            fontStyle={interest ? 'normal' : 'italic'}
          >
            {isSpeechCraftMFE && !interest
              ? 'Share your interests and goals with your fellow Speechcrafters.'
              : interest}
          </Typography>
        </InfoSection>
        {editModalData.isModalOpen ? (
          <EditFieldModal
            title={editModalData.modalTitle}
            fieldTitle={editModalData.fieldTitle}
            initialValue={editModalData.initialValue}
            handleClose={handleCloseEditModal}
          />
        ) : null}
        {!isSpeechCraftMFE && (
          <>
            <InfoSection>
              <HeadingWrapper>
                <SocialIcon />
                <Typography variant='h3' component='span' m={0}>
                  Social Media Handles
                </Typography>
              </HeadingWrapper>
              <Typography variant='body1'>
                <SocialMediaIcons gap={2.5}>
                  {twitterUrl && (
                    <a
                      rel='noreferrer'
                      href={twitterUrl}
                      target='_blank'
                      aria-label='Twitter'
                    >
                      <TwitterXIcon />
                      <Typography>@twitterhandle</Typography>
                    </a>
                  )}
                  {facebookUrl && (
                    <a
                      rel='noreferrer'
                      href={facebookUrl}
                      target='_blank'
                      aria-label='Facebook'
                    >
                      <FacebookIcon />
                      <Typography>@facebookhandle</Typography>
                    </a>
                  )}
                  {linkedinUrl && (
                    <a
                      rel='noreferrer'
                      href={linkedinUrl}
                      target='_blank'
                      aria-label='Linked In'
                    >
                      <LinkedinIcon />
                      <Typography>@linkedinhandle</Typography>
                    </a>
                  )}
                  {instagramUrl && (
                    <a
                      rel='noreferrer'
                      href={instagramUrl}
                      target='_blank'
                      aria-label='Instagram'
                    >
                      <InstagramIcon />
                      <Typography>@instahandle</Typography>
                    </a>
                  )}
                </SocialMediaIcons>
              </Typography>
            </InfoSection>
            {!isSpeechCraftMFE ? <MeetingLogs /> : null}
          </>
        )}
      </Box>
      {!isSpeechCraftMFE && (
        <Box my={5}>
          {isActiveCourseLoading ? (
            <Loader position='relative' />
          ) : (
            <CourseSection
              sectionHeading='Paths in Progress'
              showSlider={false}
              data={activeEnrolledCourses}
              canViewCourseProgress={canViewCourseProgress}
            />
          )}
        </Box>
      )}

      {isSpeechcrafterContactEditModalOpen ? (
        <SpeechcrafterContactEditModal
          title='My Contact Information'
          handleClose={handleCloseSpeechcrafterContactEditModal}
          phoneNumber={phoneNumber}
          contactEmail={contactEmail}
          intro={intro}
        />
      ) : null}
    </Box>
  );
};

export default About;
