import {
  Box,
  styled,
  ListItem,
  Paper,
  ListItemButton,
  Stack,
} from '@mui/material';
import { theme } from 'styles/theme';
import { DeviceUp, DeviceDown } from 'styles/breakpoints';

export const Container = styled(Box)`
  max-width: 1110px;
  width: 100%;
  padding: 0 15px;
  margin: 30px auto 0;

  & h2 {
    margin-bottom: 10px;
  }

  & .infinite-scroll-component {
    padding: 10px 5px;
  }
`;

export const NotificationList = styled(ListItemButton)<{
  canOpenNotification: boolean;
}>`
  cursor: ${props => (props.canOpenNotification ? 'pointer' : 'auto')};
  position: relative;
  padding: 0px 10px;
  background: ${({ theme }) => theme.palette.ternary.light};
  border-bottom: 1px solid ${({ theme }) => theme.palette.ternary.main};
  border-left: 4px solid transparent;

  &.unread-item {
    background: ${({ theme }) => theme.palette.ternary.white};
  }

  &:last-child {
    border-bottom: none;
  }

  @media ${DeviceUp.md} {
    padding: 0px 40px;
  }

  &:hover {
    border-left: 4px solid ${({ theme }) => theme.palette.primary.main};
  }

  .MuiAvatar-root {
    height: 60px;
    width: 60px;
    margin-right: 10px;
  }

  button {
    min-width: 90px;
  }
`;

export const NotificationListItem = styled(ListItem)`
  justify-content: center;
  align-items: flex-start;
  padding: 30px 0px;

  @media ${DeviceDown.xs} {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .MuiListItemText-root {
    margin-right: 15px;

    @media ${DeviceDown.xs} {
      margin-right: 0;
      width: 100%;
      order: 3;
      padding: 5px 0 0;
    }
  }

  .notify-btn {
    margin-left: auto;
    align-self: center;
  }
`;

export const CustomPaper = styled(Paper)`
  box-shadow: 0px 2px 10px rgba(0, 0, 41, 0.1);
  border-radius: 4px;

  .infinite-scroll-component {
    margin: 0 -5px;
  }
`;

export const NotificationHeader = styled(Box)``;

export const FilterWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  @media ${DeviceUp.sm} {
    flex-wrap: nowrap;
  }
`;

export const SortFieldWrapper = styled(Box)`
  width: 285px;
  padding-left: 15px;
  margin-left: auto;
  margin-bottom: 15px;

  & .MuiTypography-root {
    white-space: nowrap;
    margin-right: 5px;
  }

  & .MuiFormControl-root {
    height: 40px;
  }
`;

export const FeedbackWrapper = styled(Box)`
  max-width: 900px;
  margin: 40px auto 20px;

  .MuiButton-text {
    text-decoration: none;
  }
`;

export const BackArrow = styled(Box)`
  display: flex;
  align-items: center;

  svg {
    width: 14px;
    margin-right: 8px;

    path {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & a {
    color: ${({ theme }) => theme.palette.primary.main};
    display: flex;
    align-items: center;
  }
`;

export const FeedbackButtonsBox = styled(Stack)`
  & .MuiButton-root {
    min-width: 160px;
  }
`;

export const ResourcesWrapper = styled(Box)`
  margin: 33px 0px 18px;
`;

export const NoFeedback = styled(Box)`
  max-width: 380px;
  margin: 100px auto;
  text-align: center;

  p {
    font-size: 18px;
    line-height: 32px;
  }
`;
