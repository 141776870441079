import {
  Container,
  CustomRow,
  ContentWrapper,
  ContentHolder,
} from 'Layouts/Dashboard/style';
import PublicHeader from 'components/PublicHeader';
import Footer from 'components/Footer';

const Public = (Content: any) => (props: any) => {
  return (
    <>
      <PublicHeader />
      <Container id='main'>
        <CustomRow>
          <ContentWrapper id='scrollableDiv'>
            <ContentHolder>
              <Content {...props} />
            </ContentHolder>
            <Footer />
          </ContentWrapper>
        </CustomRow>
      </Container>
    </>
  );
};

export default Public;
