import { Box, Typography, Stack } from '@mui/material';
import { toast } from 'react-toastify';

import Button from 'elements/Button';
import { useDeleteMeetingLogMutation } from 'api/profileApi';
import { parseDate } from 'utils/utility';

const DeleteMeetingLog = ({ handleClose, deletionLog }: any) => {
  const [deleteMeetingLog, { isLoading }] = useDeleteMeetingLogMutation();

  const handleDeleteMeetingLog = async () => {
    try {
      await deleteMeetingLog(deletionLog.id).unwrap();
      toast.success('Meeting role deleted');
      handleClose();
    } catch (error) {
      toast.error('Something went wrong, please try again later!');
    }
  };

  return (
    <Box>
      <Typography variant='body1' lineHeight={1.9} textAlign='center'>
        Are you sure you want to delete the {deletionLog.name} for{' '}
        {parseDate(deletionLog.date).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })}
        ?
      </Typography>

      <Stack direction='row' gap={5} mt={10} justifyContent='center'>
        <Button onClick={handleClose} variant='outlined' disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={handleDeleteMeetingLog} disabled={isLoading}>
          Delete
        </Button>
      </Stack>
    </Box>
  );
};

export default DeleteMeetingLog;
