import { Avatar, Button, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';

import { Box as Card, BoxContent as CardContent } from 'elements/Card/style';
import GiveFeedback from 'pages/Dashboard/components/Feedback/MemberFeedback/GiveFeedback';
import { getPlatformName, getUserName } from 'utils/utility';

const ClubCard = ({ member, clubId, clubName }: any) => {
  const { username, profileImage } = member;

  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  const handleOpenFeedbackModal = () => {
    setFeedbackModalOpen(true);
  };

  const handleCloseFeedbackModal = () => {
    setFeedbackModalOpen(false);
  };

  const displayName = getUserName(member);

  return (
    <Card className='card club-card' sx={{ pb: 0 }}>
      <CardContent className='flex-column'>
        <Stack alignItems='center' textAlign='center' mb={7.5} gap={4}>
          <Avatar
            className='club-member-profile-img'
            src={profileImage.large}
            alt={`${username}'s profile image`}
            sx={{ width: 100, height: 100 }}
          />
          <Typography variant='h4'>{displayName}</Typography>
        </Stack>

        <Stack 
          className='club-card-cta-holder' 
          flexDirection='row' 
          justifyContent='space-around' 
          alignItems='center' 
          mt='auto'
          >
          <Button 
            variant='contained'
            component={RouterLink}
            to={`${getPlatformName()}/my-clubs/profile/${clubId}/${username}/about`}
          >
            View profile
          </Button>
          <Button variant='outlined' onClick={handleOpenFeedbackModal}>
            Give feedback
          </Button>
        </Stack>
      </CardContent>

      <GiveFeedback
        modalOpen={feedbackModalOpen}
        handleOpen={handleOpenFeedbackModal}
        handleClose={handleCloseFeedbackModal}
        selectedClub={clubId}
        selectedClubName={clubName}
        selectedMembers={[
          {
            id: member.id,
            name: displayName,
          },
        ]}
      />
    </Card>
  );
};

export default ClubCard;
