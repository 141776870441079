import React, { memo, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { NavBreadCrumbs } from './style';

type propsTypes = {
  links: Record<string, any>[];
};

const Breadcrumb: FunctionComponent<propsTypes> = ({ links }) => {
  return (
    <NavBreadCrumbs>
      {links.map((link: any, index: number) => {
        const isLastlink = links.length === 1 || links.length === index + 1;
        const tagName = isLastlink ? React.Fragment : Link;
        const elementProps: any = {
          key: index,
          ...(!isLastlink && { to: link.url }),
        };

        return (
          <>
            {React.createElement('li', {
              children: React.createElement(tagName, elementProps, link.label),
            })}
            {!isLastlink && (
              <li>
                <span className='separator'>/</span>
              </li>
            )}
          </>
        );
      })}
    </NavBreadCrumbs>
  );
};

Breadcrumb.defaultProps = {
  links: [],
};

export default memo(Breadcrumb);
