import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { get, uniqBy, filter } from 'lodash';
import querystring from 'querystring';

import { LMS_BASE_URL } from 'config';
import axiosClient from 'utils/client';

const initialState = {
  activeEnrolledCourses: { data: [], isLoading: false, isError: false },
  suggestedCourses: { data: {}, isLoading: false, isError: false },
  completedEnrolledCourses: { data: {}, isLoading: false, isError: false },
};

const coursesAPI = {
  async fetchActiveEnrolledCourses({ username }: any) {
    const params = {
      ...(username && {
        username,
      }),
    };

    let qs = querystring.stringify(params);
    if (qs) {
      qs = `?${qs}`;
    }

    const result = await axiosClient({
      url: `${LMS_BASE_URL}/active_enrolled_courses${qs}`,
      method: 'GET',
    });
    return result;
  },
  async fetchCompletedEnrolledCourses({ page = 1 }: any) {
    const result = await axiosClient({
      url: `${LMS_BASE_URL}/completed_enrolled_courses?page=${page}`,
      method: 'GET',
    });
    return result;
  },
  async fetchSuggestedCourses({ page = 1 }: any) {
    const result = await axiosClient({
      url: `${LMS_BASE_URL}/suggested_courses?page=${page}`,
      method: 'GET',
    });
    return result;
  },
  async postCourseRegistration({ id = 1, enrollmentAction = '' }: any) {
    let formData = new FormData();
    formData.append('course_id', id);
    formData.append('enrollment_action', enrollmentAction);
    const result = await axiosClient({
      url: `${LMS_BASE_URL}/change_enrollment`,
      data: formData,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return result;
  },
};

export const getActiveEnrolledCourses = createAsyncThunk(
  'courses/getActiveEnrolledCourses',
  async (props: any) => {
    const response = await coursesAPI.fetchActiveEnrolledCourses(props);
    return response.data;
  },
);

export const getCompletedEnrolledCourses = createAsyncThunk(
  'courses/getCompletedEnrolledCourses',
  async (props: any) => {
    const response = await coursesAPI.fetchCompletedEnrolledCourses(props);
    return response.data;
  },
);

export const getSuggestedCourses = createAsyncThunk(
  'courses/getSuggestedCourses',
  async (props: any) => {
    const response = await coursesAPI.fetchSuggestedCourses(props);
    return response.data;
  },
);

export const setCourseRegistration = createAsyncThunk(
  'courses/setCourseRegistration',
  async (props: any, thunk: any) => {
    const response = await coursesAPI.postCourseRegistration(props);
    return response.data;
  },
);

const slice = createSlice({
  name: 'enrolledCourses',
  initialState: initialState,
  reducers: {
    updateSuggestedCourses: (state: any, payload?: any) => {
      return {
        ...state,
        suggestedCourses: {
          ...state.suggestedCourses,
          data: {
            next: get(state, 'suggestedCourses.data.next', null),
            count: get(state, 'suggestedCourses.data.count', 0),
            results: filter(
              get(state, 'suggestedCourses.data.results', []),
              suggestedCourse => {
                const { course } = suggestedCourse;
                return course.id !== payload.id;
              },
            ),
          },
          isLoading: false,
          isError: false,
        },
      };
    },
    resetCourses: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getActiveEnrolledCourses.fulfilled, (state, action) => {
        state.activeEnrolledCourses.data = action.payload;
        state.activeEnrolledCourses.isLoading = false;
        state.activeEnrolledCourses.isError = false;
      })
      .addCase(getActiveEnrolledCourses.pending, state => {
        state.activeEnrolledCourses.isLoading = true;
      })
      .addCase(getActiveEnrolledCourses.rejected, state => {
        state.activeEnrolledCourses.isError = true;
        state.activeEnrolledCourses.isLoading = false;
      })
      .addCase(getSuggestedCourses.fulfilled, (state, action) => {
        const { resetInitialState } = action.meta.arg;

        if (resetInitialState) {
          return {
            ...state,
            suggestedCourses: {
              data: action.payload,
              isLoading: false,
              isError: false,
            },
          };
        }

        return {
          ...state,
          suggestedCourses: {
            ...state.suggestedCourses,
            data: {
              next: get(action, 'payload.next', null),
              count: get(action, 'payload.count', 0),
              results: uniqBy(
                [
                  ...get(action, 'payload.results', []),
                  ...get(state, `suggestedCourses.data.results`, []),
                ],
                'course.id',
              ),
            },
            isLoading: false,
            isError: false,
          },
        };
      })
      .addCase(getSuggestedCourses.pending, state => {
        state.suggestedCourses.isLoading = true;
      })
      .addCase(getSuggestedCourses.rejected, state => {
        state.suggestedCourses.isError = true;
        state.suggestedCourses.isLoading = false;
      })
      .addCase(getCompletedEnrolledCourses.fulfilled, (state, action) => {
        return {
          ...state,
          completedEnrolledCourses: {
            ...state.completedEnrolledCourses,
            data: {
              next: get(action, 'payload.next', null),
              count: get(action, 'payload.count', 0),
              results: uniqBy(
                [
                  ...get(action, 'payload.results', []),
                  ...get(state, `completedEnrolledCourses.data.results`, []),
                ],
                'course.id',
              ),
            },
            isLoading: false,
            isError: false,
          },
        };
      })
      .addCase(getCompletedEnrolledCourses.pending, state => {
        state.completedEnrolledCourses.isLoading = true;
      })
      .addCase(getCompletedEnrolledCourses.rejected, state => {
        state.completedEnrolledCourses.isError = true;
        state.completedEnrolledCourses.isLoading = false;
      })
      .addCase(setCourseRegistration.fulfilled, (state, action) => {
        state.activeEnrolledCourses.isLoading = false;
        state.suggestedCourses.isLoading = false;
      })
      .addCase(setCourseRegistration.pending, state => {
        state.suggestedCourses.isLoading = true;
        state.activeEnrolledCourses.isLoading = true;
      })
      .addCase(setCourseRegistration.rejected, state => {
        state.suggestedCourses.isError = true;
        state.suggestedCourses.isLoading = false;
        state.activeEnrolledCourses.isError = true;
        state.activeEnrolledCourses.isLoading = false;
      });
  },
});

export const { updateSuggestedCourses, resetCourses } = slice.actions;

export const { reducer } = slice;
