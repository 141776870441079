import React, { FC, useEffect, useState } from 'react';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Box,
  List,
  ListItem,
  Typography,
  Stack,
  RadioGroup,
} from '@mui/material';
import { capitalize } from 'lodash';

import { Radio } from 'elements/Radio/style';
import Button from 'elements/Button';
import Loader from 'elements/Loader';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';
import Breadcrumb from 'components/Breadcrumb';
import { getPlatformName } from 'utils/utility';
import { DashboardRoutes as Routes } from 'routes';
import {
  useGetAssessmentFormQuestionsQuery,
  useSubmitAssessmentFormMutation,
} from 'api/selfAssessmentApi';
import { useGetRolesQuery } from 'api/initializeAppApi';

import ToastmastersIcon from 'assets/images/toastmasters.svg';
import Speechcraft from 'assets/images/speechcraft.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';

import {
  Container,
  FormWrapperOuter,
  FormWrapper,
  BrandLogos,
  BreadCrumbsHolder,
} from './style';
import { useRoles } from 'hooks';

const AssessmentForm: FC<any> = () => {
  const isProfileAssesment = useRouteMatch(Routes.PROFILE_ASSESSMENT_FORM.path);
  const history = useHistory();
  const user = getAuthenticatedUser();

  const { isCoordinator, isSpeechcrafter } = useRoles();

  const [submitAssessmentForm, { isLoading: isLoadingMutation }] =
    useSubmitAssessmentFormMutation();

  const { formId, formTitle, progress, username = '' } = useParams<any>();
  const { data: events = [] } = useGetRolesQuery();

  const eventId = events[0]?.id;

  const {
    data: assessmentForm,
    isLoading,
    isSuccess,
  } = useGetAssessmentFormQuestionsQuery(
    { formId, eventId, progress, username },
    { skip: !formId || !eventId },
  );

  const [answers, setAnswers] = useState({});
  const handleAnswerChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setAnswers(prev => ({ ...prev, [target.name]: target.value }));
  };

  useEffect(() => {
    // populate answers on first load
    // do not populate for coordinator seeing speechcrafter's unsubmitted form
    if (isSuccess) {
      const submitedAnswers = {};
      if (assessmentForm.isSubmitted) {
        for (const q of assessmentForm.question) {
          submitedAnswers[q.id as keyof Object] = q.answer;
        }
      } else if (isSpeechcrafter) {
        for (const q of assessmentForm.question) {
          // @ts-ignore
          submitedAnswers[q.id] = null;
        }
      }
      setAnswers(submitedAnswers);
    }
  }, [
    assessmentForm?.question,
    assessmentForm?.isSubmitted,
    isSuccess,
    isSpeechcrafter,
  ]);

  const handleSubmit = async () => {
    const data = {
      progress: capitalize(progress),
      form: formId,
      event: eventId,
      answers: {},
      user: user.userId,
    };

    for (const key in answers) {
      // @ts-ignore
      data.answers[key] = { answer: answers[key] };
    }

    try {
      await submitAssessmentForm(data).unwrap();
      toast.success('Your response has been submitted');
      history.push(
        isProfileAssesment
          ? `${getPlatformName()}/profile/self-Assessments`
          : `${getPlatformName()}/self-assessments/assessment-details`,
      );
    } catch (error) {
      toast.error('Something went wrong, please try again later');
    }
  };

  const links = [
    {
      label: 'Home',
      url: `${getPlatformName()}`,
    },
    ...(isCoordinator
      ? [
          {
            label: 'My Cohort',
            url: `${getPlatformName()}/my-cohort`,
          },
          {
            label: 'Profile',
            url: `${getPlatformName()}/my-cohort/profile/${username}/self-Assessments`,
          },
        ]
      : [
          {
            label: `${isProfileAssesment ? 'Profile' : 'Self-Assessments'}`,
            url: `${
              isProfileAssesment
                ? `${getPlatformName()}/profile/self-Assessments`
                : `${getPlatformName()}/self-assessments/assessment-details`
            }`,
          },
        ]),
    {
      label: `${formTitle}-${capitalize(progress)}`,
    },
  ];

  useEffect(() => {
    const mainContainer = document.getElementById('main');

    if (mainContainer) {
      mainContainer.style.backgroundColor = '#F5F5F5';
    }

    return () => {
      if (mainContainer) {
        mainContainer.style.backgroundColor = '';
      }
    };
  }, []);

  const handleBackClick = () => {
    let url = `${getPlatformName()}/my-cohort/profile/${username}/self-Assessments`;

    if (isSpeechcrafter) {
      if (isProfileAssesment) {
        url = `${getPlatformName()}/profile/self-Assessments`;
      } else {
        url = `${getPlatformName()}/self-assessments/assessment-details`;
      }
    }

    history.push(url);
  };

  const isButtonDisabled = Object.values(answers).some(answer => !answer);

  return (
    <Container>
      <BreadCrumbsHolder>
        <Breadcrumb links={links} />
        <Button variant='text' className='back-btn color-primary' onClick={handleBackClick}>
          <ArrowBackIosIcon />
            Back
        </Button>
      </BreadCrumbsHolder>

      {isLoading ? <Loader position='relative' /> : null}
      {isSuccess ? (
        <>
          <FormWrapperOuter>
            <FormWrapper>
              <BrandLogos>
                <List>
                  <ListItem>
                    <img src={ToastmastersIcon} alt='Toastmasters' />
                  </ListItem>
                  <ListItem>
                    <img src={Speechcraft} alt='Pathways' />
                  </ListItem>
                </List>
              </BrandLogos>
              <Typography variant='h2' className='fw-700' pb={10}>
                {`${formTitle}-${capitalize(progress)}`}
              </Typography>
              <Typography variant='h3' className='fw-500'>
                Instructions
              </Typography>
              <Typography>
                Rate your level of agreement with each statement.
              </Typography>

              <Box py={7.5}>
                {assessmentForm?.question.map((q: any, idx: number) => {
                  let radioButtonVal = null;
                  const currentAnswer = answers[`${q.id}` as keyof Object];

                  if (currentAnswer) {
                    radioButtonVal = currentAnswer;
                  }

                  return (
                    <Box className='choices-box page-break' key={q.id}>
                      <Typography>{q.title}</Typography>
                      <Stack direction='row' className='radio-options'>
                        <RadioGroup
                          row
                          aria-labelledby='feedback-visibility-controlled-radio-buttons-group'
                          name={`${q.id}`}
                          value={radioButtonVal}
                          onChange={handleAnswerChange}
                        >
                          {[1, 2, 3, 4, 5].map(option => (
                            <FormControlLabel
                              disabled={
                                assessmentForm?.isSubmitted || isCoordinator
                              }
                              key={option}
                              value={option}
                              control={<Radio size='small' />}
                              label={q[`choice${option}`]}
                            />
                          ))}
                        </RadioGroup>
                      </Stack>
                    </Box>
                  );
                })}
              </Box>

              {isSpeechcrafter ? (
                <Stack flexDirection='row' gap={2}>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      history.push(
                        isProfileAssesment
                          ? `${getPlatformName()}/profile/self-Assessments`
                          : `${getPlatformName()}/self-assessments/assessment-details`,
                      );
                    }}
                    className='mw120'
                    disabled={assessmentForm?.isSubmitted}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    onClick={handleSubmit}
                    disabled={
                      assessmentForm?.isSubmitted ||
                      isLoadingMutation ||
                      isButtonDisabled
                    }
                    className='mw120'
                  >
                    {assessmentForm?.isSubmitted ? 'Submitted' : 'Submit'}
                  </Button>
                </Stack>
              ) : null}
            </FormWrapper>
          </FormWrapperOuter>
        </>
      ) : null}
    </Container>
  );
};

export default AssessmentForm;
