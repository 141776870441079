import React, { FC, useState, useRef, useEffect, useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { map, get } from 'lodash';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import compose from 'compose-function';

import StyledBadge from 'components/Badge';
import Accordion from 'components/Accordion';
import SpeechHistory from 'pages/Dashboard/components/Achievements/SpeechHistory';
import { HOC } from 'elements/Modal';
import UpdateSpeechHistory from 'pages/Dashboard/components/UpdateSpeechHistory';
import { BADGES_FILTER_TYPE, BADGE_HASH_TYPES } from 'config';
import Chip from 'elements/Chip';
import { useIsSpeechCraftMFE } from 'hooks';
import { getIsUserHijacked } from 'utils/utility';

import {
  useGetLearnigBadgesQuery,
  useGetFeedbacksBadgesQuery,
  useGetSpeechHistoryQuery,
  useGetSpeechCraftBadgesQuery,
  useGetCoursesCertificatesQuery,
} from 'api/achievementApi';
import useProfilePage from 'pages/Profile/useProfilePage';
import {
  toggleConfirmation,
  toggleUpdateSpeechHistory,
} from 'pages/Modals/ducks/slice';
import Confirmation from 'components/Confirmation';
import { ConfirmationHistoryHOC } from 'pages/Dashboard/container/ConfirmationHistory';
import {
  getUpdateSpeechHistorySelector,
  getConfirmationSelector,
} from 'pages/Modals/ducks/selectors';
import {
  ChipWrapper,
  BadgeElement,
  LearningBadgesWrapper,
  LearningBadgesRow,
  FeedbackBadgesWrapper,
  ClearAll,
} from 'pages/Dashboard/components/Achievements/style';
import Loader from 'elements/Loader';

import BadgeSharing from './BadgeSharing';
import LevelCard from './LevelCard';

const INITIAL_STATE = {
  learnerBadge: 'learnerBadge',
  feedbackBadge: 'feedbackBadge',
  speechBadge: 'speechBadge',
  speechHistory: 'speechHistory',
  certificates: 'certificates', // TODO: remove me maybe
};

const SpeechHistoryModal = compose(HOC)(UpdateSpeechHistory);
const ConfirmationModal = compose(HOC, ConfirmationHistoryHOC)(Confirmation);

const BadgeSharingModal = compose(HOC)(BadgeSharing);

const Achievements: FC<any> = () => {
  const [activeAccordions, setActiveAccordions] = useState<any>(INITIAL_STATE);
  const [filter, setFilter] = useState(BADGES_FILTER_TYPE.ALL);
  const [learningBadges, setLearningBadges] = useState<any>([]);
  const [sharingBadge, setSharingBadge] = useState({});
  const [isBadgeSharingModalOpen, setIsBadgeSharingModalOpen] = useState(false);

  const isSpeechCraftMFE = useIsSpeechCraftMFE();
  const learnerBadgeRef = useRef<HTMLElement>(null);
  const feedbackBadgeRef = useRef<HTMLElement>(null);
  const speechBadgeRef = useRef<HTMLElement>(null);
  const { isMemberProfilePage, username } = useProfilePage();
  const isUserHijacked = getIsUserHijacked();

  const openBadgeSharingModal = (badge: any) => {
    if (isMemberProfilePage) {
      return;
    }

    setSharingBadge(badge);
    setIsBadgeSharingModalOpen(true);
  };
  const closeBadgeSharingModal = () => {
    setSharingBadge({});
    setIsBadgeSharingModalOpen(false);
  };

  const dispatch = useDispatch();

  const { status: isModalOpen, payload: speechHistory } = useSelector(
    getUpdateSpeechHistorySelector,
  );
  const { status: isConfirmationModalOpen, data: valuesToUpdate } = useSelector(
    getConfirmationSelector,
  );

  const { data: learningBadgesData, isLoading: isLearningBadgesLoading } =
    useGetLearnigBadgesQuery(username, {
      skip: isSpeechCraftMFE,
    });

  const { data: feedbackBadges, isLoading: isFeedbackBadgesLoading } =
    useGetFeedbacksBadgesQuery(username);

  const { data: speechCraftBadges, isLoading: isSpeechCraftBadgesLoading } =
    useGetSpeechCraftBadgesQuery(username, {
      skip: !isSpeechCraftMFE,
    });
  const { isLoading: isSpeechHistoryLoading, data: speechHistoryData = [] } =
    useGetSpeechHistoryQuery({ username }, { skip: isSpeechCraftMFE });

  const { isLoading: isCertificatesLoading, data: coursesCeritficates = [] } =
    useGetCoursesCertificatesQuery(username);

  const handleChangeAccordion = (name: string, panel: string | false) => {
    setActiveAccordions((prev: Record<string, string>) => ({
      ...prev,
      [name]: panel,
    }));
  };

  const handleChangeFilter = (value: any) => {
    setFilter(value);
  };

  const handleKeyPress = (event: any, badge: any) => {
    if (isMemberProfilePage) {
      return;
    }

    if ((event.key === ' ' || event.key === 'Enter') && badge.isEarned) {
      openBadgeSharingModal(badge);
    }
  };

  useEffect(() => {
    const filterdBadges = map(learningBadgesData, badges => {
      return badges.filter(
        ({ isEarned }: any) =>
          filter === BADGES_FILTER_TYPE.ALL ||
          (filter === BADGES_FILTER_TYPE.COMPLETED ? isEarned : !isEarned),
      );
    });
    setLearningBadges(filterdBadges);
  }, [filter, learningBadgesData]);

  useEffect(() => {
    if (
      window.location.hash === `${BADGE_HASH_TYPES.learnerBadge}` &&
      !isLearningBadgesLoading
    ) {
      learnerBadgeRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    if (
      window.location.hash === `${BADGE_HASH_TYPES.feedbackBadge}` &&
      !isFeedbackBadgesLoading
    ) {
      setTimeout(() => {
        feedbackBadgeRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 1000);
    }

    if (
      window.location.hash === `${BADGE_HASH_TYPES.speechBadge}` &&
      !isFeedbackBadgesLoading
    ) {
      setTimeout(() => {
        speechBadgeRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 1000);
    }
  }, [
    isFeedbackBadgesLoading,
    isLearningBadgesLoading,
    isSpeechCraftBadgesLoading,
  ]);

  return (
    <Box sx={{ mb: 10 }}>
      {!isSpeechCraftMFE && (
        <Box ref={learnerBadgeRef}>
          <Accordion
            accordianId={INITIAL_STATE.learnerBadge}
            activeAccordion={activeAccordions.learnerBadge}
            handleChangeAccordion={handleChangeAccordion}
            title='Learning Badges'
            isLoading={isLearningBadgesLoading}
          >
            <Box sx={{ mt: 4 }}>
              <ChipWrapper>
                <Chip
                  label='All'
                  className={classNames('filter-chip filter-chip-secondary', {
                    active:
                      filter !== BADGES_FILTER_TYPE.AVAILABLE &&
                      filter !== BADGES_FILTER_TYPE.COMPLETED,
                  })}
                  handleClick={() => handleChangeFilter(BADGES_FILTER_TYPE.ALL)}
                />
                <Chip
                  label='Earned'
                  className={classNames('filter-chip filter-chip-secondary', {
                    active: filter === BADGES_FILTER_TYPE.COMPLETED,
                  })}
                  handleClick={() =>
                    handleChangeFilter(BADGES_FILTER_TYPE.COMPLETED)
                  }
                />
                <Chip
                  label='Available to Earn'
                  className={classNames('filter-chip filter-chip-secondary', {
                    active: filter === BADGES_FILTER_TYPE.AVAILABLE,
                  })}
                  handleClick={() =>
                    handleChangeFilter(BADGES_FILTER_TYPE.AVAILABLE)
                  }
                />
              </ChipWrapper>
              <LearningBadgesWrapper>
                {map(learningBadges, (courseLearningBadges, index) => {
                  return (
                    <LearningBadgesRow key={`learningBadge${index}`}>
                      {map(courseLearningBadges, badge => (
                        <BadgeElement
                          key={badge?.id}
                          className={classNames({
                            disabled: !badge.isEarned,
                            hovered:
                              badge.isEarned &&
                              !isMemberProfilePage &&
                              !isUserHijacked,
                          })}
                          onKeyPress={event =>
                            !isUserHijacked && handleKeyPress(event, badge)
                          }
                        >
                          <StyledBadge
                            className='learner-badge-holder'
                            badgeImage={badge.badgeMeta.image}
                            altText={badge.badgeMeta.name}
                            badgeContent={badge.badgeEarnCount}
                            isDisabled={!badge.isEarned}
                            canShareBadge={
                              !isMemberProfilePage && !isUserHijacked
                            }
                            handleClick={
                              badge.isEarned
                                ? () =>
                                    !isUserHijacked &&
                                    openBadgeSharingModal(badge)
                                : undefined
                            }
                          />
                          <Typography
                            className='badge-title'
                            onClick={
                              badge.isEarned
                                ? () =>
                                    !isUserHijacked &&
                                    openBadgeSharingModal(badge)
                                : undefined
                            }
                          >
                            {badge.badgeMeta.name}
                          </Typography>
                        </BadgeElement>
                      ))}
                    </LearningBadgesRow>
                  );
                })}
              </LearningBadgesWrapper>
            </Box>
          </Accordion>
        </Box>
      )}
      {isSpeechCraftMFE && (
        <Box ref={speechBadgeRef}>
          <Accordion
            accordianId={INITIAL_STATE.speechBadge}
            activeAccordion={activeAccordions.speechBadge}
            handleChangeAccordion={handleChangeAccordion}
            title='Speech Completion Badges'
            isLoading={isSpeechCraftBadgesLoading}
          >
            <FeedbackBadgesWrapper>
              {map(speechCraftBadges, badge => {
                return (
                  <BadgeElement
                    key={badge?.id}
                    tabIndex={!badge.isEarned ? -1 : 0}
                    role='button'
                    className={classNames({
                      disabled: !badge.isEarned,
                      hovered:
                        badge.isEarned &&
                        !isMemberProfilePage &&
                        !isUserHijacked,
                    })}
                    onKeyPress={event =>
                      !isUserHijacked && handleKeyPress(event, badge)
                    }
                  >
                    <StyledBadge
                      badgeImage={badge.badgeMeta.image}
                      altText={badge.badgeMeta.name}
                      badgeContent={badge.badgeEarnCount}
                      isDisabled={!badge.isEarned}
                      canShareBadge={!isMemberProfilePage && !isUserHijacked}
                      handleClick={
                        badge.isEarned
                          ? () =>
                              !isUserHijacked && openBadgeSharingModal(badge)
                          : undefined
                      }
                    />
                    <Typography
                      className='badge-title'
                      onClick={
                        badge.isEarned
                          ? () =>
                              !isUserHijacked && openBadgeSharingModal(badge)
                          : undefined
                      }
                    >
                      {badge.badgeMeta.name}
                    </Typography>
                  </BadgeElement>
                );
              })}
            </FeedbackBadgesWrapper>
          </Accordion>
        </Box>
      )}
      <Box ref={feedbackBadgeRef}>
        <Accordion
          accordianId={INITIAL_STATE.feedbackBadge}
          activeAccordion={activeAccordions.feedbackBadge}
          handleChangeAccordion={handleChangeAccordion}
          title='Feedback Badges'
          isLoading={isFeedbackBadgesLoading}
        >
          <FeedbackBadgesWrapper>
            {map(feedbackBadges, badge => (
              <BadgeElement
                key={badge?.id}
                tabIndex={!badge.isEarned ? -1 : 0}
                role='button'
                className={classNames({
                  disabled: !badge.isEarned,
                  hovered:
                    badge.isEarned && !isMemberProfilePage && !isUserHijacked,
                })}
                onKeyPress={event =>
                  !isUserHijacked && handleKeyPress(event, badge)
                }
              >
                <StyledBadge
                  badgeImage={badge.badgeMeta.image}
                  altText={badge.badgeMeta.name}
                  badgeContent={badge.badgeEarnCount}
                  isDisabled={!badge.isEarned}
                  canShareBadge={!isMemberProfilePage && !isUserHijacked}
                  handleClick={
                    badge.isEarned
                      ? () => !isUserHijacked && openBadgeSharingModal(badge)
                      : undefined
                  }
                />
                <Typography
                  className='badge-title'
                  onClick={
                    badge.isEarned
                      ? () => !isUserHijacked && openBadgeSharingModal(badge)
                      : undefined
                  }
                >
                  {badge.badgeMeta.name}
                </Typography>
              </BadgeElement>
            ))}
          </FeedbackBadgesWrapper>
        </Accordion>
      </Box>

      <Box sx={{ ...(isCertificatesLoading && { mb: 0 }) }}>
        {isCertificatesLoading ? (
          <Loader
            LoadingMessage='Loading course certificates...'
            direction='column'
          />
        ) : (
          coursesCeritficates.map((course: any, index: number) => {
            const courseId = `${course.name}${index}`;

            return (
              <Box key={`${course.name}${index}`}>
                <Accordion
                  accordianId={courseId}
                  activeAccordion={activeAccordions[courseId]}
                  handleChangeAccordion={handleChangeAccordion}
                  title={`${course.name} Certificates`}
                >
                  <Grid container columnSpacing={5} rowSpacing={3}>
                    {map(
                      get(course, 'certificates', []),
                      (certificate, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={3}
                          key={`${certificate.name}${index}`}
                        >
                          <LevelCard
                            certificate={certificate}
                            isMemberProfilePage={isMemberProfilePage}
                          />
                        </Grid>
                      ),
                    )}
                  </Grid>
                </Accordion>
              </Box>
            );
          })
        )}
      </Box>

      {!isSpeechCraftMFE && (
        <Box>
          <Accordion
            accordianId={INITIAL_STATE.speechHistory}
            activeAccordion={activeAccordions.speechHistory}
            handleChangeAccordion={handleChangeAccordion}
            title='Speech Log'
            isLoading={isSpeechHistoryLoading}
          >
            {speechHistoryData.map((data: any, index: any) => (
              <SpeechHistory
                key={data.id}
                data={data}
                isMemberProfilePage={isMemberProfilePage}
              />
            ))}
          </Accordion>
        </Box>
      )}

      {isModalOpen && (
        <SpeechHistoryModal
          title='Speech Log'
          handleClose={() => dispatch(toggleUpdateSpeechHistory({}))}
          handleUpdate={() =>
            dispatch(toggleUpdateSpeechHistory(speechHistory))
          }
          speechHistory={speechHistory}
        />
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          title='Confirmation'
          buttonText='Save'
          showCloseIcon={false}
          closeOnOutsideClick={false}
          handleClose={() => {
            dispatch(toggleUpdateSpeechHistory(speechHistory));
            dispatch(toggleConfirmation({} as any));
          }}
          data={valuesToUpdate}
        >
          Are you sure you want to save changes to this Speech Log?
        </ConfirmationModal>
      )}

      {isBadgeSharingModalOpen && (
        <BadgeSharingModal
          title='Share Badge'
          handleClose={closeBadgeSharingModal}
          badge={sharingBadge}
        />
      )}
    </Box>
  );
};

export default Achievements;
