import React, { FC, useEffect, useCallback, useMemo } from 'react';

import { Container, NavBreadCrumbs } from 'pages/CourseList/style';
import { useHistory, Redirect, useParams, Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { get, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';

import Loader from 'elements/Loader';
import { COURSE_CATEGORY } from 'config';
import SectionHeader from 'components/SectionHeader';
import CourseCard from 'pages/Dashboard/components/CourseCard';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getPlatformName, getQueryParam } from 'utils/utility';

import {
  getSuggestedCourses,
  getCompletedEnrolledCourses,
  setCourseRegistration,
  updateSuggestedCourses,
} from 'pages/Dashboard/ducks/slice';
import {
  getSuggestedCoursesSelector,
  getCompletedCoursesSelector,
} from 'pages/Dashboard/ducks/selectors';

const renderBackButton = (
  <div className='back-btn'>
    <ArrowBackIosIcon />
    Back Home
  </div>
);
const CourseList: FC<any> = () => {
  const history = useHistory();
  const { category } = useParams<any>();
  const dispatch = useDispatch<any>();

  const { isLoading: isSuggestedCoursesLoading, data: suggestedCourses } =
    useSelector(getSuggestedCoursesSelector);

  const { isLoading: isCompletedCoursesLoading, data: completedCourses } =
    useSelector(getCompletedCoursesSelector);

  const isSuggestedCourses = category === COURSE_CATEGORY.SUGGESTED;
  const isCompletedCourses = category === COURSE_CATEGORY.COMPLETED;
  const isValidCategory = isSuggestedCourses || isCompletedCourses;
  const isLoading = isCompletedCoursesLoading || isSuggestedCoursesLoading;
  const coursesData = useMemo(
    () =>
      !isValidCategory
        ? {}
        : isSuggestedCourses
        ? suggestedCourses
        : completedCourses,
    [completedCourses, isSuggestedCourses, isValidCategory, suggestedCourses],
  );

  const getNextData = useCallback(() => {
    const next = get(coursesData, 'next', null);
    const { page }: any = getQueryParam(next || '');
    const props = { page };

    if (!next) {
      return;
    }

    if (isSuggestedCourses) {
      dispatch(getSuggestedCourses(props));
      return;
    }

    dispatch(getCompletedEnrolledCourses(props));
  }, [coursesData, dispatch, isSuggestedCourses]);

  const handleActionButton = () => {
    history.push(`${getPlatformName()}`);
  };

  useEffect(() => {
    const props = { page: 1 };

    if (!isValidCategory) {
      return;
    }

    if (isSuggestedCourses) {
      dispatch(getSuggestedCourses(props));

      return;
    }

    dispatch(getCompletedEnrolledCourses(props));
  }, [dispatch, isSuggestedCourses, isValidCategory]);

  if (!isValidCategory) {
    return <Redirect to={`${getPlatformName()}`} />;
  }

  const courseCategory = isCompletedCourses
    ? 'Completed'
    : 'Suggested Learning';

  const handleCardRegistration = async (id: any, enrollmentAction: any) => {
    try {
      await dispatch(setCourseRegistration({ id, enrollmentAction })).unwrap();

      dispatch(updateSuggestedCourses({ id } as any));
    } catch (err) {}
  };

  return (
    <Container>
      <NavBreadCrumbs>
        <li>
          <Link to={`${getPlatformName()}`}>Paths & Learning</Link>
        </li>
        <li>
          <span className='separator'>/</span>
        </li>
        <li>{courseCategory}</li>
      </NavBreadCrumbs>

      <SectionHeader
        title={courseCategory}
        buttonText={renderBackButton}
        handleActionButton={handleActionButton}
      />

      <InfiniteScroll
        dataLength={get(coursesData, 'results', []).length}
        next={getNextData}
        hasMore={get(coursesData, 'next', '') ? true : false}
        loader={
          <div className='loader' key={0}>
            <Loader />
          </div>
        }
        scrollableTarget='scrollableDiv'
      >
        <Grid container spacing={3}>
          {map(get(coursesData, 'results'), (cardData, index) => (
            <Grid item xs={12} sm={4} md={3} key={index}>
              <CourseCard
                data={cardData}
                handleCardRegistration={handleCardRegistration}
                isLoading={isSuggestedCoursesLoading}
              />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    </Container>
  );
};

export default CourseList;
