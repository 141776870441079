import React, { createElement } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';

import { DashboardRoutes as Routes } from 'routes';
import { useEffect, useMemo } from 'react';
import { findIndex, get, map } from 'lodash';

import SpeechCompletion from './Components/SpeechCompletion';
import SpeechcraftLearning from './Components/SpeechcraftLearning';
import Assessments from './Components/Assessments';
import { TabLinksWrapper, TabPanelsWrapper } from 'pages/Dashboard/style';
import { getPlatformName } from 'utils/utility';

interface TabPanelProps {
  children?: React.ReactNode;
  value: any;
  tabAriaControls: any;
  index: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, tabAriaControls, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={tabAriaControls}
      aria-labelledby={tabAriaControls}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const SpeechDashboard = () => {
  const { activeOption } = useParams<any>();
  const routerParams = useParams<any>();
  const history = useHistory();

  useEffect(() => {
    if (!activeOption) {
      history.push(
        generatePath(Routes.SPEECH_DASHBOARD.path, {
          activeOption: 'speech-completion',
        }),
      );
    }
  }, [activeOption, history]);

  const activeSection = get(routerParams, 'activeOption', 'speech-completion');
  const tabs = React.useMemo(
    () => [
      {
        ariaControls: 'speechcompletion',
        label: 'Speech Completion',
        id: 'speech-completion',
        visibility: true,
        component: SpeechCompletion,
        disabled: false,
      },
      {
        ariaControls: 'assessments',
        label: 'Assessments',
        id: 'assessments',
        visibility: true,
        component: Assessments,
      },
      {
        ariaControls: 'speechcrafterlearning',
        label: 'Speechcrafter Learning',
        id: 'speechcraft-learning',
        visibility: true,
        component: SpeechcraftLearning,
      }
    ], []
    );

  const getActiveTabIndex = useMemo(() => {
    const index = findIndex(
      tabs.filter(tab => tab.visibility),
      tab => activeSection === tab.id,
    );

    if (index >= 0) {
      return index;
    }

    return 0;
  }, [activeSection, tabs]);

  return (
    <Box mb={15}>
      <TabLinksWrapper>
        <Box sx={{ borderBottom: 2, borderColor: 'transparent' }}>
          <Tabs
            TabIndicatorProps={{
              children: <span className='MuiTabs-indicatorSpan' />,
            }}
            value={getActiveTabIndex}
            aria-label='Speechcraft dashboard tabs'
            selectionFollowsFocus
            scrollButtons
            variant='scrollable'
            allowScrollButtonsMobile
          >
            {map(
              tabs.filter(tab => tab.visibility),
              (tab, index) => (
                <Tab
                  key={index}
                  aria-controls={tab.ariaControls}
                  label={tab.label}
                  component={Link}
                  to={`${getPlatformName()}/speech-dashboard/${tab.id}`}
                />
              ),
            )}
          </Tabs>
        </Box>
      </TabLinksWrapper>
      <TabPanelsWrapper>
        {map(
          tabs.filter(tab => tab.visibility),
          (tab, index) =>
            createElement(TabPanel, {
              tabAriaControls: tab.ariaControls,
              value: getActiveTabIndex,
              key: index,
              index,
              children: createElement(tab.component),
            }),
        )}
      </TabPanelsWrapper>
    </Box>
  );
};

export default SpeechDashboard;
