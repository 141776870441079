import React, { FunctionComponent } from 'react';
import { map } from 'lodash';

import { Box, RadioGroup, Stack, Typography } from '@mui/material';

import { Radio } from 'elements/Radio/style';
import TextField from 'elements/Form/TextField';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';

import { ErrorMessage } from './style';
import { QUESTION_OPTIONS } from './constants';

const Questions: FunctionComponent<any> = ({
  questions = {},
  handleUpdateQuestion,
  isReadonlyMode,
  errors = {},
  touched = {},
}) => {
  return (
    <>
      <Typography py={7}>
        For the evaluator: In addition to your verbal evaluation, please
        complete this form.
      </Typography>
      {map(questions, question => {
        return (
          <Box className='choices-box page-break'>
            <Typography>
              <Typography component='strong'>{question.title}:</Typography>{' '}
              {question.description}
            </Typography>
            <Stack direction='row' className='radio-options'>
              <RadioGroup
                row
                aria-labelledby='feedback-visibility-controlled-radio-buttons-group'
                name={`q${question.id}`}
                value={question.answer}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleUpdateQuestion(event, 'answer')
                }
              >
                {QUESTION_OPTIONS.map(option => (
                  <FormControlLabel
                    value={option.value}
                    control={<Radio size='small' />}
                    label={option.label}
                    disabled={isReadonlyMode}
                  />
                ))}
              </RadioGroup>
            </Stack>
            {!!touched[`q${question.id}`]?.answer && !!errors[`q${question.id}`]?.answer && (
              <ErrorMessage>{errors[`q${question.id}`]?.answer}</ErrorMessage>
            )}
            <FormControlLabel
              label='Comments:'
              labelPlacement='top'
              control={
                <TextField
                  fullWidth
                  multiline
                  name={`q${question.id}`}
                  value={question.comment}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleUpdateQuestion(event, 'comment')
                  }
                  disabled={isReadonlyMode}
                />
              }
            />
          </Box>
        );
      })}
    </>
  );
};

export default Questions;
