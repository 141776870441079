import { FC, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from 'elements/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { StyledAutocomplete, StyledPopper, StyledLabel, GroupHeader, GroupItems } from 'elements/AutoComplete/style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { isEmpty } from 'lodash';
import { getCategoryNameById } from 'utils/utility';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CheckboxesAutoComplete: FC<any> = ({options, limitTags = 2, placeholder, label, selectedOptions, defaultValue, error, helperText, isGroupedByItems}) => {
  const [hasData, setHasData] = useState([]);

  return (
    <StyledAutocomplete
      multiple
      limitTags={limitTags}
      id="checkboxes-tags"
      popupIcon={<KeyboardArrowDownIcon />}
      options={options}
      groupBy={(option: any) => option.category}
      value={defaultValue}
      disableCloseOnSelect
      PopperComponent={StyledPopper}
      ChipProps={{ deleteIcon: <CloseIcon /> }}
      className={!isEmpty(hasData) || !isEmpty(defaultValue) ? 'has-data' : ''}
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option : any) => option.name}
      onChange={(event, value: any) => {
        selectedOptions(value)
        setHasData(value)
      }}
      renderOption={(props, option: any, { selected }) => {
        return (
          <li {...props} key={option.id}>
            <StyledLabel
              control={
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  value={selected}
                />
              } label={option.name} />
          </li>
        )
      }}
      renderGroup={(params) => (
        <li>
          {!isEmpty(isGroupedByItems) && !isEmpty(getCategoryNameById(isGroupedByItems, params.group)) && <GroupHeader>{getCategoryNameById(isGroupedByItems, params.group)}</GroupHeader>}
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      renderInput={(params) => (
        <StyledLabel
          label={label}
          value=""
          labelPlacement="top"
          control={<TextField {...params} placeholder={placeholder} name={label} error={!!error} helperText={helperText} fullWidth />}
        />
      )}
    />
  );
}

CheckboxesAutoComplete.defaultProps = {
  isGroupedByItems: []
}


export default CheckboxesAutoComplete;
