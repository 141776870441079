import { styled } from '@mui/material';
import { DeviceDown, DeviceUp } from 'styles/breakpoints';

export const Container = styled('div')`
  flex: 1;
  overflow: hidden;
`;

export const CustomRow = styled('div')`
  height: 100%;
  display: flex;

  @media ${DeviceDown.md} {
    flex-direction: column;
  }

  #scrollableDiv {
    overflow-x: hidden;
  }
`;

export const ContentWrapper = styled('div')`
  flex: 1;
  height: 100%;
  overflow: auto;
`;

export const ReportHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;

  h1 {
    margin: 0;
    font-family: 'Grotesk';
    font-weight: 500;
    letter-spacing: 0.5px;
  }
`;

export const ContentHolder = styled('div')`
  /* overflow-y: hidden; */
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: calc(100vh - 140px);
`;
