import { FC } from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from 'elements/Form/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormHelperText from '@mui/material/FormHelperText';
import CloseIcon from '@mui/icons-material/Close';

import {
  StyledFormControl,
  StyledFormLabel,
  CloseIconWrapper,
} from 'elements/DropdownField/style';

const DropdownField: FC<any> = ({
  label,
  value,
  handleChange,
  error,
  options = [],
  name,
  helperText,
  className,
  isLoading,
  disabled,
  placeholder,
  handleClearSelection,
}) => {
  return (
    <StyledFormControl error={!!error} fullWidth disabled={disabled}>
      {label && (
        <StyledFormLabel id='select-field-label'>{label}</StyledFormLabel>
      )}
      <Select
        name={name}
        value={value}
        className={className}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={(props: any) => <ExpandMoreIcon {...props} />}
        fullWidth
        displayEmpty
        renderValue={(value: any) => {
          if (!value) {
            return <em>{placeholder}</em>;
          }

          const { label } =
            options.find(
              (option: any) => String(option.value) === String(value),
            ) || {};

          return label;
        }}
      >
        {isLoading ? (
          <MenuItem value=''>
            <em>Loading</em>
          </MenuItem>
        ) : (
          options.map((option: any, index: any) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        )}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {handleClearSelection && value && (
        <CloseIconWrapper>
          <CloseIcon onClick={() => handleClearSelection()} />
        </CloseIconWrapper>
      )}
    </StyledFormControl>
  );
};

export default DropdownField;
