import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, Download } from '@mui/icons-material';
import compose from 'compose-function';
import { toast } from 'react-toastify';
import useDownloader from 'react-use-downloader';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import ArticleIcon from '@mui/icons-material/Article';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import ErrorIcon from '@mui/icons-material/Error';
import DownloadingIcon from '@mui/icons-material/Downloading';

import axiosClient from 'utils/client';
import { StyledDocCard } from 'pages/Dashboard/components/Resources/Documents/style';
import { useDeleteDocumentMutation } from 'api/documentsApi';
import { HOC } from 'elements/Modal';
import DeleteConfirmation from 'components/DeleteConfirmation';
import { resetDocuments, fetchDocuments } from './documentSlice';
import { getFileType, FileType } from 'utils/utility';

import sampleImg from 'assets/images/icons/img.svg';
import sampleDoc from 'assets/images/icons/docx.svg';
import sampleVideo from 'assets/images/icons/mp4.svg';
import sampleUnknown from 'assets/images/sample-unknown.png';
import PDFIcon from 'assets/images/icons/pdf.svg';
import PPTIcon from 'assets/images/icons/ppt.svg';

const DeleteConfirmationModal = compose(HOC)(DeleteConfirmation);

const DocumentCard = (props: any) => {
  const { document } = props;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const [deleteDocument, { isLoading }] = useDeleteDocumentMutation();

  const dispatch = useDispatch();

  const { type: fileType, extension: fileExtension } = getFileType(
    document.fileName,
  );

  const { download, isInProgress } = useDownloader({
    cache: 'no-cache',
  });

  const handleDownloadDoc = async () => {
    try {
      const res = await axiosClient.get(document.file);
      await download(res.data.fileUrl, document.fileName);
    } catch (error) {
      toast.error('Download failed! Please try again later');
    }
  };

  const handleDeleteDoc = async () => {
    try {
      await deleteDocument(document.id).unwrap();
      toast.success(`The file has been deleted.`);
      window.scrollTo(0, 0);
      dispatch(resetDocuments());
      dispatch(fetchDocuments());
      closeDeleteModal();
    } catch (error) {
      toast.error(`Something went wrong`);
    }
  };


  return (
    <>
      <Tooltip title={document.fileName} placement='top'>
        <StyledDocCard>
          <CardMedia
            component='img'
            height='113'
            image={
              fileType === FileType.Image
                ? sampleImg
                : fileType === FileType.Document && fileExtension === "pdf"
                ? PDFIcon
                : fileType === FileType.Document && ( fileExtension === "ppt" || fileExtension === "pptx")
                ? PPTIcon
                : fileType === FileType.Document
                ? sampleDoc
                : fileType === FileType.Video
                ? sampleVideo
                : sampleUnknown
            }
            alt='sample image'
          />

          <Box className='cardContent-bottom'>
            <CardContent className='CardContent-doc'>
              <Stack direction='row' alignItems='center' gap={2}>
                <Typography
                  variant='body2'
                  fontWeight={600}
                  overflow='hidden'
                  textOverflow='ellipsis'
                  whiteSpace='nowrap'
                >
                  {document.fileName}
                </Typography>
              </Stack>
            </CardContent>

            <CardActions className='CardActions-doc'>
              <IconButton
                aria-label='download'
                size='small'
                disabled={isInProgress}
                onClick={handleDownloadDoc}
              >
                {isInProgress ? (
                  <DownloadingIcon color='secondary' />
                ) : (
                  <Download color='secondary' />
                )}
              </IconButton>

              <IconButton
                aria-label='delete'
                size='small'
                onClick={openDeleteModal}
                disabled={isLoading}
              >
                <Delete color='secondary' />
              </IconButton>
            </CardActions>
          </Box>
        </StyledDocCard>
      </Tooltip>

      {isDeleteModalOpen ? (
        <DeleteConfirmationModal
          card={document}
          isLoading={isLoading}
          handleClose={closeDeleteModal}
          handleDelete={handleDeleteDoc}
        />
      ) : null}
    </>
  );
};

export default DocumentCard;
