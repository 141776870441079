import { FC, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Stack, IconButton, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { capitalize } from 'lodash';

import TextField from 'elements/Form/TextField';
import { getPlatformName, getUserName } from 'utils/utility';
import Breadcrumb from 'components/Breadcrumb';
import Loader from 'elements/Loader';

import SpeechcraftersTable from './SpeechcraftTable';
import { SearchForm } from './style';
import { useAppContext } from 'contexts/AppContext';
import { useGetSpeechcraftLearningMutation } from 'api/speechDashboardApi';
import { generatePath } from 'react-router-dom';
import { DashboardRoutes as Routes } from 'routes';
import { getActiveCoursesSelector } from 'pages/Dashboard/ducks/selectors';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';
import { useSelector } from 'react-redux';
import { FieldWrapper } from 'elements/Form/style';
import AutoComplete from 'elements/AutoComplete';

import { getActiveEnrolledCourses } from 'pages/Dashboard/ducks/slice';

const links = [
  {
    label: 'Home',
    url: `${getPlatformName()}`,
  },
  {
    label: 'My Cohort',
  },
];

const SpeechcraftLearning: FC<any> = () => {
  const [searchName, setSearchName] = useState('');
  const [filter, setFilter] = useState('');
  const [course, setCourse] = useState<any>(null);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const dispatch = useDispatch();
  const { event = {} } = useAppContext();

  const { isLoading: isActiveCourseLoading, data: activeEnrolledCourses = [] } =
    useSelector(getActiveCoursesSelector);
  const [
    getSpeechcraftLearners,
    { data = [], isLoading: isSpeechcratLearnersLoading, isSuccess },
  ] = useGetSpeechcraftLearningMutation();

  const coursesOptions = useMemo(
    () =>
      activeEnrolledCourses.map((courseData: any) => {
        const { course } = courseData;
        return { id: course.id, name: course.name };
      }),
    [activeEnrolledCourses],
  );

  useEffect(() => {
    setCourse(coursesOptions[0] || null);
  }, [coursesOptions]);

  useEffect(() => {
    const rows =
      data.map(({ user, status }: any) => ({
        name: getUserName(user),
        profileUrl: generatePath(Routes.SPEECHCRAFTER_PROFILE.path, {
          username: user.username,
          activeSection: 'about',
        }),
        profileImage: user.profileImage.medium,
        status,
      })) || [];

    setRows(rows);
    setFilteredRows(rows);
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (filter) {
      setFilteredRows((prevRows: any) =>
        prevRows.filter((row: any) =>
          capitalize(row.name).includes(capitalize(filter)),
        ),
      );
    }
  }, [filter]);

  useEffect(() => {
    dispatch(getActiveEnrolledCourses({}));
  }, [dispatch]);

  useEffect(() => {
    if (!course) {
      setRows([]);
      setFilteredRows([]);
      return;
    }

    getSpeechcraftLearners(encodeURIComponent(course.id));
  }, [JSON.stringify(course)]);

  const handleChangeCourse = (value: any) => {
    setFilter('');
    setSearchName('');
    setCourse(value);
  };

  const isLoading = isSpeechcratLearnersLoading;

  return (
    <Box className='w-100'>
      <Breadcrumb links={links} />
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        flexWrap='wrap'
        pt={4}
        mb={4}
      >
        <Typography variant='h2'>Speechcrafters</Typography>
        <Stack direction='row' flexWrap='wrap' alignItems='end' gap={2}>
          <Box minWidth={280}>
            <FieldWrapper mb={4}>
              <FormControlLabel
                label='Select Course'
                labelPlacement='top'
                disabled={isActiveCourseLoading}
                control={
                  <AutoComplete
                    id='select-speech-crafters'
                    options={coursesOptions || []}
                    loading={isActiveCourseLoading}
                    loadingText='Loading courses'
                    getOptionLabel={(option: any) => option.name}
                    defaultValue={course}
                    selectedOptions={handleChangeCourse}
                    placeholder='Select Course'
                    name='speech'
                    disabled={isActiveCourseLoading}
                  />
                }
              />
            </FieldWrapper>
          </Box>
          <SearchForm minWidth={280} mb={4}>
            <TextField
              disabled={isSpeechcratLearnersLoading || isActiveCourseLoading}
              variant='standard'
              placeholder='Search'
              value={searchName}
              onChange={(event: any) => {
                const { value } = event.target;
                setSearchName(value);

                if (!value) {
                  setFilter('');
                  setFilteredRows(rows);
                }
              }}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  const { value } = event.target;
                  setSearchName(value);

                  if (!value) {
                    setFilter('');
                    setFilteredRows(rows);
                  }
                }
              }}
            />
            <IconButton onClick={() => setFilter(searchName)} aria-label="Search">
              <SearchIcon />
            </IconButton>
          </SearchForm>
        </Stack>
      </Stack>
      {isLoading ? (
        <Stack my={10} justifyContent='center'>
          <Loader />
        </Stack>
      ) : (
        <SpeechcraftersTable rows={filteredRows} />
      )}
    </Box>
  );
};

export default SpeechcraftLearning;
