import { FunctionComponent } from 'react';
import {
  Avatar,
  Typography,
  Box,
  ListItemText,
  ListItemAvatar,
  Button,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import dummyImage from 'assets/images/dummyImage.png';
import { timeAgo } from 'utils/utility';
import {
  NOTIFICATION_TYPES,
  APPROVAL_REQUEST_STATUS,
  TOASTMASTERS_WEBSITE_URL,
} from 'config';
import { useUpdateUnreadNotificationMutation } from 'api/notificationApi';
import { useNotifications } from 'components/NotificationsMenu/useNotifications';
import { useDispatch } from 'react-redux';
import { markNotificationRead } from './ducks/slice';
import {
  NotificationList,
  NotificationListItem,
  NotificationHeader,
} from './style';

const NotificationItem: FunctionComponent<any> = ({ notification, shouldOpenModal }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    notificationText,
    redirectRoute,
    notificationType,
    isSenderReceiver,
    displayName,
    status,
    shouldRedirectToToastmasterWebsite,
    isDeleted,
    hasOpenAction,
  } = useNotifications(notification, shouldOpenModal);
  const [updateUnReadNotifications] = useUpdateUnreadNotificationMutation();

  const handleClick = () => {
    if (notification.unread) {
      updateUnReadNotifications(notification.slug).then(() => {
        dispatch(markNotificationRead(notification.slug));
      });
    }

    if (status === APPROVAL_REQUEST_STATUS.Reversed) {
      toast.info(
        `The request has become obsolete. The learner's progress may have been reversed. For further information, please reach out to our support team.`,
      );

      return;
    }

    if (shouldRedirectToToastmasterWebsite) {
      window.location.assign(`${TOASTMASTERS_WEBSITE_URL}start-pathways/select-your-preference`);

      return;
    }

    if (!redirectRoute) {
      return;
    }

    if (
      NOTIFICATION_TYPES.pathwaysMentorProgramEnrollment === notificationType ||
      NOTIFICATION_TYPES.clubCoachModuleEnrollment === notificationType ||
      NOTIFICATION_TYPES.preCourseEnrollment === notificationType ||
      NOTIFICATION_TYPES.courseInactivity === notificationType ||
      NOTIFICATION_TYPES.courseEnrollment === notificationType ||
      (NOTIFICATION_TYPES.completionReview === notificationType && notification?.target?.status !== 'Denied') ||
      (notificationType === NOTIFICATION_TYPES.completionReviewNotifyBCM &&
        isSenderReceiver) ||
      NOTIFICATION_TYPES.speechcrafterCertificate === notificationType
    ) {
      window.location.assign(redirectRoute);
      return;
    }
    history.push(redirectRoute);
  };

  const canOpenNotification = hasOpenAction && !isDeleted;

  return (
    <NotificationList
      role='list'
      className={notification.unread ? 'unread-item' : 'read-item'}
      onClick={canOpenNotification ? handleClick : () => {}}
      // @ts-ignore
      canOpenNotification={canOpenNotification}
    >
      <NotificationListItem disableGutters disablePadding>
        <ListItemAvatar>
          <Avatar
            alt='user image'
            src={
              NOTIFICATION_TYPES.completionReviewNotifyBCM === notificationType
                ? dummyImage
                : notification.sender.profileImage.small
            }
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <Box className=''>
                <NotificationHeader>
                  <Typography
                    variant='h4'
                    component='span'
                    className='fw-700 text-capitalize'
                  >
                    {displayName}
                  </Typography>{' '}
                  {' - '}
                  <Typography variant='h4' component='span' className='fw-700'>
                    {timeAgo(notification.timestamp)}
                  </Typography>
                </NotificationHeader>
                <Box>
                  <div
                    dangerouslySetInnerHTML={{ __html: notificationText }}
                  ></div>
                </Box>
              </Box>
            </>
          }
          sx={{ paddingTop: 1.5 }}
        />
        {canOpenNotification ? (
          <Button className='notify-btn' variant='outlined' tabIndex={-1}>
            Open
          </Button>
        ) : null}
      </NotificationListItem>
    </NotificationList>
  );
};

export default NotificationItem;
