import { FunctionComponent } from 'react';
import { useTheme, Stack, Typography } from '@mui/material';
import { PieChart, Pie, ResponsiveContainer, Label, Tooltip } from 'recharts';

import { CompletionProgressCard } from './style';

const data01 = [{ value: 10 }];

const CustomLabel = ({ viewBox, totalAttendies, validatedAttendies }: any) => {
  const { cx, cy } = viewBox;

  return (
    <g>
      <text
        x={cx}
        y={cy}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'
        alignmentBaseline='middle'
        fontSize='48'
        fontWeight='800'
        style={{ lineHeight: 58 }}
      >
        {`${validatedAttendies}/${totalAttendies}`}
      </text>
      <text
        x={cx}
        y={cy + 30}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'
        alignmentBaseline='middle'
        fontSize='12'
        fontWeight='700'
      >
        COMPLETED
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, ...label }: any) => {
  if (
    active &&
    payload &&
    payload.length &&
    payload[0]?.payload?.fill === 'url(#colorUv)'
  ) {
    return (
      <div className='custom-tooltip'>
        <div className='chart-legend'>
          <Typography variant='h4' className='complete-legend'>
            Completed
          </Typography>
        </div>
        <Typography variant='body2' className='fw-700' mt={3}>
          {payload[0]?.payload?.tooltipText}
        </Typography>
      </div>
    );
  }

  return null;
};

const CompletionProgress: FunctionComponent<any> = ({ data = {} }) => {
  const theme = useTheme();

  const {
    speechName,
    validatedAttendies,
    completedNames,
    progress,
    totalAttendies,
  } = data;

  const oldRange = 100;
  const newRange = 360;
  const endAngle = -(((progress - oldRange) * newRange) / oldRange + 270);

  const pieChartData = [
    {
      name: speechName,
      tooltipText: `${completedNames} have completed the “${speechName}” speech.`,
      status: progress === 100,
      completedNames,
      value: progress,
    },
  ];

  return (
    <CompletionProgressCard>
      <Typography variant='h5' mb={5}>
        {`${speechName} Completed`}
      </Typography>
      <ResponsiveContainer aspect={1}>
        <PieChart>
          <defs>
            <linearGradient id='colorUv' x1='1' y1='1' x2='0' y2='0'>
              <stop
                offset='30%'
                stopColor={theme.palette.secondary.main}
                stopOpacity={1}
              />
              <stop
                offset='100%'
                stopColor={theme.palette.secondary.light}
                stopOpacity={1}
              />
            </linearGradient>
          </defs>
          <Pie
            data={data01}
            dataKey='value'
            startAngle={90}
            endAngle={-270}
            innerRadius={100}
            outerRadius={150}
            fill={theme.palette.ternary.main}
            isAnimationActive={false}
            aria-label={speechName}
          >
            {!progress && (
              <Label
                content={
                  <CustomLabel
                    totalAttendies={totalAttendies}
                    validatedAttendies={validatedAttendies}
                  />
                }
                position='center'
              />
            )}
          </Pie>
          <Pie
            data={pieChartData}
            dataKey='value'
            startAngle={90}
            endAngle={endAngle}
            innerRadius={95}
            outerRadius={150}
            fill={`url(#colorUv)`}
            aria-label={speechName}
          >
            <Label
              content={
                <CustomLabel
                  totalAttendies={totalAttendies}
                  validatedAttendies={validatedAttendies}
                />
              }
              position='center'
            />
          </Pie>
          {/* <Tooltip content={<CustomTooltip active={true} />} /> */}
        </PieChart>
      </ResponsiveContainer>
      <Stack className='chart-legend' direction='row' gap={8} mt={3}>
        <Typography className='incomplete-legend' variant='h5'>
          Incomplete
        </Typography>
        <Typography className='complete-legend' variant='h5'>
          Completed
        </Typography>
      </Stack>
    </CompletionProgressCard>
  );
};

export default CompletionProgress;
