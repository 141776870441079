import { useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

import Container from 'elements/Container';
import FormStepper from 'pages/SpeechValidation/Stepper';
import FormStep from 'pages/SpeechValidation/Stepper/FormStep';
import GeneralInformation from './Components/GeneralInformation';
import Recipients from './Components/Recipients';
import Confirmation from './Components/Confirmation';
import { DashboardRoutes as Routes } from 'routes';
import {
  generalInformationScheema,
  recipientsScheema,
  confirmationScheema,
} from './validationScheema';
import { useAppContext } from 'contexts/AppContext';
import { useAwardCertificatesMutation } from 'api/speechCertificatesApi';

const _initialValues = {
  event: null,
  issueDate: null,
  attendees: [],
  coordinatorCheck1: false,
  coordinatorCheck2: false,
  coordinatorCheck3: false,
};

const SpeechCertificates = () => {
  const history = useHistory();
  const { event } = useAppContext();

  const [activeStep, setActiveStep] = useState(0);
  const [initialValues, setInitialValues] = useState({
    ..._initialValues,
    event,
  });

  const [awardCertificates, { isLoading }] = useAwardCertificatesMutation();

  const handleSubmit = async (
    data: any,
    activeStep: any,
    helpers: any,
    handleNext: any,
  ) => {
    if (activeStep === 2) {
      const payload = {
        event: data.event.id,
        issueDate: dayjs(data.issueDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        attendees: [] as any,
      };

      for (const attendee of data.attendees) {
        payload.attendees.push(attendee.id);
      }

      try {
        const { success, errors } = await awardCertificates(payload).unwrap();

        if (isEmpty(errors)) {
          toast.success('Certificates awarded');
          history.push(
            generatePath(Routes.DASHBOARD.path, {
              activeSection: 'coordinator-home',
            }),
          );
        } else if (isEmpty(success)) {
          toast.error('Something went wrong');
        } else {
          toast.warning('Partial certificates awarded');
          history.push(
            generatePath(Routes.DASHBOARD.path, {
              activeSection: 'coordinator-home',
            }),
          );
        }
      } catch (error) {
        toast.error('Something went wrong');
      }
    } else {
      handleNext();
    }
  };

  return (
    <Container>
      <FormStepper
        title='Certificates'
        isNextDisabled={isLoading}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        step={activeStep}
        handleClose={() => {
          history.push(
            generatePath(Routes.DASHBOARD.path, {
              activeSection: 'coordinator-home',
            }),
          );
        }}
        enableReinitialize
        ConfirmationButtonText='Confirm'
      >
        <FormStep
          label='General Information'
          validationSchema={generalInformationScheema}
        >
          <GeneralInformation />
        </FormStep>

        <FormStep label='Recipients' validationSchema={recipientsScheema}>
          <Recipients />
        </FormStep>

        <FormStep label='Confirmation' validationSchema={confirmationScheema}>
          <Confirmation />
        </FormStep>
      </FormStepper>
    </Container>
  );
};

export default SpeechCertificates;
