import React, { memo, FunctionComponent } from 'react';
import { StyledLabel, ErrorMsg } from 'elements/FormControlLabel/style';

const FormControlLabel: FunctionComponent<any> = ({
  label,
  labelPlacement,
  control,
  error,
  props
}) => {
  return (
    <>
      <StyledLabel
        label={label}
        labelPlacement={labelPlacement}
        control={control}
        {...props}
      />
      {error && <ErrorMsg className='error-msg'>{error}</ErrorMsg> }
    </>
  );
};

FormControlLabel.defaultProps = {
  fullWidth: true,
};

export default memo(FormControlLabel);
