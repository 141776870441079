import { FC } from 'react';
import TextField from 'elements/Form/TextField';
import { StyledDesktopDatePicker as DesktopDatePicker } from './style';

const DatePicker: FC<any> = ({
  value,
  handleChange,
  error,
  helperText,
  className,
  disabled,
  placeholder,
  minDate,
  disableFuture = false,
}) => {
  return (
    <DesktopDatePicker
      inputFormat='MMMM DD, YYYY'
      value={value}
      onChange={handleChange}
      className={className}
      disableMaskedInput={true}
      disabled={disabled}
      minDate={minDate}
      disableFuture={disableFuture}
      PopperProps={{
        placement: 'bottom-end',
      }}
      renderInput={props => (
        <TextField
          {...props}
          fullWidth
          error={error}
          helperText={helperText}
          inputProps={{
            ...props.inputProps,
            placeholder,
          }}
        />
      )}
    />
  );
};

export default DatePicker;
