import {
  Container,
  CustomRow,
  ContentWrapper,
  ContentHolder,
} from 'Layouts/Dashboard/style';
import Header from 'components/Header';
import ModalsPanel from 'pages/Modals';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';
// @ts-ignore
import { getConfig } from '@edx/frontend-platform';
import Loader from 'elements/Loader';
import Sidebar from 'components/Sidebar';
import Footer from 'components/Footer';

const Dashboard = (Content: any) => (props: any) => {
  const user = getAuthenticatedUser();
  if (!user) {
    const loginUrl = new URL(`${getConfig().LMS_BASE_URL}/login`);
    loginUrl.searchParams.append('next', window.location.href);
    window.location.replace(loginUrl.href);
  }

  if (!user) {
    return <Loader position='fixed' background='none' />;
  }

  return (
    <>
      <Header user={user} />
      <Container id='main'>
        <CustomRow>
          {/* <Sidebar /> */}
          <ContentWrapper id='scrollableDiv'>
            <ContentHolder>
              <Content {...props} />
            </ContentHolder>
            <Footer />
          </ContentWrapper>
        </CustomRow>
      </Container>
      <ModalsPanel />
    </>
  );
};

export default Dashboard;
