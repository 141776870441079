import { FunctionComponent, useState } from 'react';
import { map, truncate } from 'lodash';
import classNames from 'classnames';
import { Stack, Typography } from '@mui/material';
import useDownloader from 'react-use-downloader';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';

import Chip from 'elements/Chip';
import Card from 'elements/Card';
import { DescriptionHolder } from 'elements/Card/style';

import { CardActions } from 'pages/Dashboard/components/Resources/style';
import { CardActionsHolder } from 'pages/Dashboard/components/CourseCard/style';
import axiosClient from 'utils/client';
import { LMS_BASE_URL } from 'config';

const CardItem: FunctionComponent<any> = ({
  data = {},
  handleOpenAssetPreview,
  handleFavoriteAsset,
  disableFavorite = {},
  categoryId,
  handleShareAsset,
}) => {
  const { asset = {}, favorite, id: resourceId } = data;
  const { download, isInProgress } = useDownloader({
    cache: 'no-cache',
  });

  const {
    thumbnail,
    keywords = [],
    fileName,
    thumbnailDescription,
    description,
    id,
    uuid,
    assetExtension,
    subCategory,
    language
  } = asset;

  const handleDownloadAsset = () => {
    axiosClient({
      url: `${LMS_BASE_URL}/api/asset_management/assets/${uuid}/download/`,
      method: 'GET',
    }).then(resp => {
      download(resp.data.fileUrl, `${fileName}${assetExtension}`);
    });
  };

  const isDisabled = !!disableFavorite[asset.id];

  return (
    <Card
      cardImage={thumbnail}
      altText={thumbnailDescription}
      handleClick={() =>
        handleOpenAssetPreview({
          ...asset,
          resourceId,
          favorite,
          categoryId,
        })
      }
      actionButtons = {
      <CardActionsHolder>
        <CardActions direction='row' className='card-cta-icons-wrapper'>
          <div className='card-cta-icons'>
            <button aria-label='Download' onClick={() => !isInProgress && handleDownloadAsset()}>
              <DownloadOutlinedIcon />
            </button>
            <button aria-label='Share' onClick={() => handleShareAsset(asset)}>
              <ShareOutlinedIcon />
            </button>
            <button className={classNames({'favorited-icon' : favorite})} aria-label={favorite ? 'Favorite' : 'Unfavorite'}
            onClick={() =>
              !isDisabled &&
              handleFavoriteAsset(asset.id, !favorite, resourceId, categoryId)
            }>
              {favorite ? (
                <FavoriteOutlinedIcon />
              ) : (
                <FavoriteBorderOutlinedIcon />
              )}
            </button>
          </div>
        </CardActions>
      </CardActionsHolder>
      }
    >
      <Stack
        className='flexwrap'
        direction='row'
        spacing={1}
        justifyContent='flex-start'
      >
        {map(language, (label: any, index: number) => (
          <Chip label={label.name} key={index} className='filter-chip' />
        ))}
      </Stack>
      <Typography variant='h3' className='fw-700' title={fileName}>
        {fileName}
      </Typography>
      {description &&
        <DescriptionHolder>
          <Typography variant='body1'>
            {truncate(description, {
              length: 85,
              separator: ' ',
            })}
          </Typography>
        </DescriptionHolder>
      }
    </Card>
  );
};

export default CardItem;
