import { styled, Box as CategorySectionSliderBox } from "@mui/material";
import {theme} from 'styles/theme';

export const SilderWrapper = styled(CategorySectionSliderBox)`
    margin: 0 -3px 20px;
    position: relative;

    .slick-list {
        padding-bottom: 5px;
    }

    .slick-track {
        margin: 0;
        display: flex;
        padding-top: 5px;

        .card {
            margin: 0 0 10px;
            max-width: 100%;
            min-width: 100%;
        }
    }

    .slick-slide {
        padding: 0 6px;
        height: auto;

        & > div {
            height: 100%;
        }
    }

    .slick-arrow {
        width: 24px;
        height: 24px;
        position: absolute;
        left: auto;
        right: 75px;
        top: -20px;
        border-radius: 4px;

        &:focus-visible {
            outline: 3px solid ${({ theme }) => theme.palette.ternary.focusBlue};
            outline-offset: 2px;
        }

        &::before {
            display: none;
        }

        &.slick-disabled {
            svg {
                color: ${({ theme }) => theme.palette.ternary.greyShade};
            }
        }

        &.slick-prev {
            right: 105px;

            &::after {
                content: "";
                position: absolute;
                margin: auto;
                top: 0;
                bottom: 0;
                right: -3px;
                width: 1px;
                height: 14px;
                background: ${({ theme }) => theme.palette.ternary.main};
                font-size: 0;
            }
        }

        &:hover {
            color: ${({ theme }) => theme.palette.primary.light};
            background: ${({ theme }) => theme.palette.ternary.main};
        }
    }
`;
