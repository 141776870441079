// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';
import { getPlatformName, getUserName } from 'utils/utility';
import dayjs from 'dayjs';
import { generatePath } from 'react-router-dom';

import {
  NOTIFICATION_TYPES,
  LEARNING_APP_BASE_URL,
  APPROVAL_REQUEST_STATUS,
  BADGE_TYPES,
  BADGE_HASH_TYPES,
  TICourses,
} from 'config';
import { DashboardRoutes as Routes } from 'routes';

const getLevelPathCompletionText = (
  requestType: string,
  status: string,
  isLastLevel: boolean,
  levelNo: number,
  pathName: string,
  approvedBy: Record<string, any>,
): string => {
  const isLevelRequest = requestType?.toLocaleLowerCase() === 'level';
  const isPathRequest = requestType?.toLocaleLowerCase() === 'path';

  const isLevelOrPathApproved = status === APPROVAL_REQUEST_STATUS.Approved;
  const isLevelOrPathDenied = status === APPROVAL_REQUEST_STATUS.Denied;
  const isLevelOrPathReversed = status === APPROVAL_REQUEST_STATUS.Reversed;

  const isDTMPath = pathName.toLowerCase().startsWith(TICourses.DTM);
  const isPMPPath = pathName.toLowerCase().startsWith(TICourses.PMP);

  if (isLevelOrPathApproved || isLevelOrPathReversed) {
    if (isDTMPath) {
      return '🎉You finished the Distinguished Toastmaster project; congratulations on your accomplishment! Where will your learning journey take you next?';
    }

    if (isPMPPath) {
      return "✅ You've completed the Pathways Mentor Program! Reach out to your VPE about becoming a mentor.";
    }

    if (isLastLevel && isLevelRequest) {
      return `🎉 Your Level ${levelNo} completion is approved! Review the final project and make a plan to complete this path.`;
    }

    if (isLevelRequest) {
      return `🎉 Your Level ${levelNo} completion is approved! Explore Level ${++levelNo} now.`;
    }

    if (isPathRequest) {
      return `🎉 You finished the ${pathName} path; congratulations on your accomplishment! Where will your learning journey take you next?`;
    }
  }

  if (isLevelOrPathDenied) {
    if (isDTMPath) {
      return `❗ Action needed: Your completion request for the DTM project was denied by ${getUserName(
        approvedBy,
      )}.`;
    }

    if (isPMPPath) {
      return `❗ Action needed: Your completion request for the Pathways Mentor Program was denied by ${getUserName(
        approvedBy,
      )}.`;
    }

    if (isLevelRequest) {
      return `❗ Action needed: Your completion request for Level ${levelNo} of the ${pathName} path was denied by ${getUserName(
        approvedBy,
      )}. Learn what to fix and resubmit.`;
    }

    return `❗ Action needed: Your completion request for ${pathName} path was denied by ${getUserName(
      approvedBy,
    )}. Learn what to fix and resubmit.`;
  }

  return '';
};

const getNotificationSenderName = (
  sender: Record<string, string>,
  notificationType: string,
  requestType: string,
  isApprovedByAdmin: boolean = false,
): any => {
  if (notificationType === NOTIFICATION_TYPES.completionReviewNotifyBCM) {
    return `${requestType} Completion`;
  }

  const reverseTypes = [
    NOTIFICATION_TYPES.reverse_completion_level,
    NOTIFICATION_TYPES.reverse_completion_path,
    NOTIFICATION_TYPES.reverse_completion_project,
  ];

  if (
    (notificationType === NOTIFICATION_TYPES.completionReview &&
      isApprovedByAdmin) ||
    reverseTypes.includes(notificationType)
  ) {
    return `WHQ Admin`;
  }

  return getUserName(sender);
};

const getFeedbackNotificationText = (notification: any = {}) => {
  const { target = {} } = notification;
  const { isSenderCoordinator = false, isReceiverCoordinator = false } = target;
  const isSenderSpeechcrafter = !isSenderCoordinator;
  const isReceiverSpeechcrafter = !isReceiverCoordinator;

  if (notification.isSpeechcraft) {
    if (isSenderSpeechcrafter && isReceiverSpeechcrafter) {
      return `✏️ ${getUserName(notification.sender, true)} gave you feedback!`;
    }

    if (isSenderSpeechcrafter && isReceiverCoordinator) {
      return `📧 ${getUserName(
        notification.sender,
      )} sent you a message! View and respond.`;
    }

    return `✏️ Your Speechcraft Coordinator, ${getUserName(
      notification.sender,
    )}, gave you feedback!`;
  }

  return `✏️ ${getUserName(notification.sender, true)} gave you feedback!`;
};

const noTargetExceptions = [
  NOTIFICATION_TYPES.reverse_completion_project,
  NOTIFICATION_TYPES.reverse_completion_level,
  NOTIFICATION_TYPES.reverse_completion_path,
];

const getCompletionRequestText = (
  member: any,
  requestType: any,
  levelNo: any,
  pathName: any,
) => {
  const pathNameLower = pathName.toLowerCase();

  if (pathNameLower.startsWith(TICourses.DTM)) {
    return `❗Action needed: Review and approve/deny ${getUserName(
      member,
      true,
    )}'s DTM project completion request in the approvals section of the Base Camp Manager page.`;
  }

  if (pathNameLower.startsWith(TICourses.PMP)) {
    return `❗Action needed: Review and approve/deny ${getUserName(
      member,
      true,
    )}'s Pathways Mentor Program completion request in the approvals section of the Base Camp Manager page.`;
  }

  return `❗Action needed: Review and approve/deny ${getUserName(
    member,
    true,
  )}'s ${
    requestType?.toLocaleLowerCase() === 'level'
      ? `Level ${levelNo}`
      : `${pathName} path`
  } completion request on the "Approvals" page.`;
};

const getCompletionReviewNotifyBCMText = (
  isApprovedByAdmin: any,
  approvedBy: any,
  status: any,
  requestType: any,
  member: any,
  levelNo: any,
  pathName: any,
  modified: any,
) => {
  const pathNameLower = pathName.toLowerCase();

  if (pathNameLower.startsWith(TICourses.DTM)) {
    return `✅ Action no longer needed: ${
      isApprovedByAdmin ? 'WHQ Admin' : getUserName(approvedBy)
    } ${status?.toLocaleLowerCase()} ${getUserName(
      member,
    )}'s DTM project completion request on ${dayjs(modified)?.format(
      'MMMM DD',
    )}.`;
  }

  if (pathNameLower.startsWith(TICourses.PMP)) {
    return `✅ Action no longer needed: ${
      isApprovedByAdmin ? 'WHQ Admin' : getUserName(approvedBy)
    } ${status?.toLocaleLowerCase()} ${getUserName(
      member,
    )}'s Pathways Mentor Program completion request on ${dayjs(
      modified,
    )?.format('MMMM DD')}.`;
  }

  return `
  ✅ Action no longer needed: ${
    isApprovedByAdmin ? 'WHQ Admin' : getUserName(approvedBy)
  } ${status?.toLocaleLowerCase()} ${getUserName(member)}'s ${
    requestType?.toLocaleLowerCase() === 'level'
      ? `Level ${levelNo} ${pathName}`
      : `${pathName} path`
  } completion request on ${dayjs(modified)?.format('MMMM DD')}.`;
};

const getCompletionRequestReminderText = (
  member: any,
  requestType: any,
  levelNo: any,
  pathName: any,
  type: any,
) => {
  const pathNameLower = pathName.toLowerCase();

  if (pathNameLower.startsWith(TICourses.DTM)) {
    return `❗ Action needed: Approve ${getUserName(
      member,
      true,
    )}'s DTM project completion request. This request was submitted ${
      type === NOTIFICATION_TYPES.completionRequestSecondReminder
        ? '3 weeks'
        : '7 days'
    } ago.`;
  }

  if (pathNameLower.startsWith(TICourses.PMP)) {
    return `❗ Action needed: Approve ${getUserName(
      member,
      true,
    )}'s Pathways Mentor Program completion request. This request was submitted ${
      type === NOTIFICATION_TYPES.completionRequestSecondReminder
        ? '3 weeks'
        : '7 days'
    } ago.`;
  }

  return `❗ Action needed: Approve ${getUserName(member, true)}'s ${
    requestType?.toLocaleLowerCase() === 'level'
      ? `Level ${levelNo}`
      : `${pathName} path`
  } completion request. Request was submitted ${
    type === NOTIFICATION_TYPES.completionRequestSecondReminder
      ? '3 weeks'
      : '7 days'
  } ago.`;
};

export const useNotifications = (
  notification: any = {},
  shouldOpenModal: any = false,
) => {
  const { type, target, slug, data, isSpeechcraft } = notification;

  const user = getAuthenticatedUser();

  const {
    type: requestType,
    courseName,
    status,
    courseId,
    isSubmitted: isEvalFormSumitted,
    member,
    approvedBy,
    speechLog,
    isLastLevel,
    levelNo,
    pathName,
    modified,
    badge,
    enrollment,
    isApprovedByAdmin,
    asset,
    isShifted,
  } = target || {};

  const { badgeType, badgeMeta } = badge || {};

  const getNotificationText = () => {
    if (!target && !noTargetExceptions.includes(type)) {
      return {
        notificationText:
          'This notification is no longer available. This can happen if the action was reversed or the member no longer belongs to your club.',
        redirectRoute: ``,
        isDeleted: true,
      };
    }

    if (isShifted) {
      return {
        notificationText:
          'This notification is no longer available. This can happen if the credit association was changed.',
        redirectRoute: ``,
        isDeleted: true,
      };
    }

    const checkCondition =
      (target && target?.status?.toLowerCase() === 'pending') ||
      (target &&
        target?.status?.toLowerCase() !== 'pending' &&
        approvedBy?.id === user?.userId);

    switch (type) {
      case NOTIFICATION_TYPES.feedbackSent:
        return {
          notificationText: getFeedbackNotificationText(notification),
          redirectRoute: `${getPlatformName()}/feedback/${
            isSpeechcraft ? 'messages-feedback' : 'member-feedback'
          }`,
        };

      case NOTIFICATION_TYPES.feedbackRequested:
        return {
          notificationText: notification.isSpeechcraft
            ? `🔔 ${getUserName(
                notification.sender,
              )} requested feedback from you! Give them your thoughts.`
            : `${getUserName(
                notification.sender,
                true,
              )} sent you a feedback request. View their request and provide specific comments.`,
          redirectRoute: shouldOpenModal
            ? `${getPlatformName()}/notifications/${slug}`
            : `${getPlatformName()}/notifications/`, //redirect to notification
        };

      case NOTIFICATION_TYPES.courseEnrollment:
        return {
          notificationText: `You have successfully enrolled in ${courseName} Path`,
          redirectRoute: `${LEARNING_APP_BASE_URL}/course/${courseId}/home`,
        };

      case NOTIFICATION_TYPES.clubAssociationChange:
        return {
          notificationText: `${getUserName(
            member,
            true,
          )} has transferred credit for their ${
            requestType?.toLocaleLowerCase() === 'level'
              ? `Level ${levelNo} of ${pathName}`
              : `${pathName} path`
          } completion to your club. No action is needed from you as this request was approved by another club.`,
          redirectRoute: ``,
          isDeleted: true,
        };

      case NOTIFICATION_TYPES.completionRequest:
        return {
          notificationText: getCompletionRequestText(
            member,
            requestType,
            levelNo,
            pathName,
          ),
          redirectRoute: checkCondition
            ? generatePath(Routes.BCM_DASHBOARD.path, {
                notificationId: slug,
                activeOption: 'approvals',
              })
            : '',
          isDeleted: !checkCondition,
        };

      case NOTIFICATION_TYPES.completionReview:
        return {
          notificationText: getLevelPathCompletionText(
            requestType,
            status,
            isLastLevel,
            levelNo,
            pathName,
            approvedBy,
          ),
          redirectRoute:
            status === 'Denied'
              ? shouldOpenModal
                ? `${getPlatformName()}/notifications/${slug}`
                : `${getPlatformName()}/notifications/`
              : `${LEARNING_APP_BASE_URL}/course/${courseId}/home`,
        };

      case NOTIFICATION_TYPES.feedbackBadgeEarned:
        return {
          notificationText:
            notification.isSpeechcraft &&
            notification.target?.isSenderCoordinator
              ? `🏆 Your Speechcraft Coordinator, ${getUserName(
                  notification.sender,
                  true,
                )}, gave you the ${badgeMeta.name} badge!`
              : `🏆 ${getUserName(notification.sender, true)} gave you the ${
                  badgeMeta.name
                } badge!
            `,
          redirectRoute: `${getPlatformName()}/feedback/${
            isSpeechcraft ? 'messages-feedback' : 'member-feedback'
          }`,
        };

      case NOTIFICATION_TYPES.badgeEarned:
        return {
          notificationText: `Congratulation! You've earned a new ${
            badgeType === BADGE_TYPES.speechBadge
              ? 'speech'
              : badgeType === BADGE_TYPES.learnerBadge
              ? badge.level
                ? 'level'
                : 'path'
              : ''
          } badge`,
          redirectRoute: `${getPlatformName()}/achievements${
            BADGE_TYPES.learnerBadge || BADGE_TYPES.speechBadge
              ? BADGE_HASH_TYPES.learnerBadge
              : BADGE_HASH_TYPES.feedbackBadge
          }`,
        };

      case NOTIFICATION_TYPES.evaluationRequest:
        return {
          notificationText: `📋 ${getUserName(
            notification.sender,
            true,
          )} has identified you as an evaluator for their ${dayjs(
            speechLog?.speechDate,
          )?.format('MMMM DD')} speech! Click to access the evaluation form.`,
          redirectRoute: `${getPlatformName()}/requests`,
        };

      case NOTIFICATION_TYPES.evaluationRequestReview:
        return {
          notificationText: `🔔 You have a new speech evaluation! See what ${getUserName(
            notification.sender,
            true,
          )} shared.`,
          redirectRoute: `${getPlatformName()}/feedback/evaluation-form/${
            target?.id
          }?type=submitted`,
        };

      case NOTIFICATION_TYPES.completionReviewNotifyBCM:
        return {
          notificationText: getCompletionReviewNotifyBCMText(
            isApprovedByAdmin,
            approvedBy,
            status,
            requestType,
            member,
            levelNo,
            pathName,
            modified,
          ),
          redirectRoute:
            member.id === user.userId
              ? `${LEARNING_APP_BASE_URL}/course/${courseId}/home`
              : generatePath(Routes.BCM_DASHBOARD.path, {
                  notificationId: slug,
                  activeOption: 'approvals',
                }),
          isSenderReceiver: member.id === user.userId,
        };

      case NOTIFICATION_TYPES.resourceShared:
        return {
          notificationText: `${getUserName(
            notification.sender,
            true,
          )} shared the ${asset?.fileName} with you!`,
          redirectRoute: `${getPlatformName()}/notifications/resources/${slug}`,
        };

      case NOTIFICATION_TYPES.evaluationDirectReview:
        return {
          notificationText: 'I have filled out your Evaluation form',
          redirectRoute: `${getPlatformName()}/feedback/evaluation-form/${
            target?.id
          }?type=submitted`,
        };

      case NOTIFICATION_TYPES.preCourseEnrollment:
        return {
          notificationText: `Thank you for buying ${courseName} again. You will be notified once re-enrollment is complete`,
          redirectRoute: '',
        };

      case NOTIFICATION_TYPES.completionRequestFirstReminder:
      case NOTIFICATION_TYPES.completionRequestSecondReminder:
        return {
          notificationText: getCompletionRequestReminderText(
            member,
            requestType,
            levelNo,
            pathName,
            type,
          ),
          redirectRoute: checkCondition
            ? generatePath(Routes.BCM_DASHBOARD.path, {
                notificationId: slug,
                activeOption: 'approvals',
              })
            : '',
          isDeleted: !checkCondition,
        };

      case NOTIFICATION_TYPES.courseInactivity:
        return {
          notificationText: `💡 It’s been a while since you worked on ${enrollment?.courseName}. Take a look at where you left off.`,
          redirectRoute: `${LEARNING_APP_BASE_URL}/course/${enrollment?.courseId}/home`,
        };

      case NOTIFICATION_TYPES.pathwaysMentorProgramEnrollment:
        return {
          notificationText: `✨ You’ve decided to elevate your leadership skills as a Pathways Mentor! Get started in the program today.`,
          redirectRoute: `${LEARNING_APP_BASE_URL}/course/${courseId}/home`,
        };

      case NOTIFICATION_TYPES.clubCoachModuleEnrollment:
        return {
          notificationText:
            'You’re registered for the Club Coach Module! Get started to unlock more leadership potential and build your coaching skills!',
          redirectRoute: `${LEARNING_APP_BASE_URL}/course/${courseId}/home`,
        };

      case NOTIFICATION_TYPES.speechcrafterCertificate:
        return {
          notificationText:
            '🙌 You completed Speechcraft! Access your Speechcraft certificate here.',
          redirectRoute: notification.data?.certificateUrl,
        };

      case NOTIFICATION_TYPES.speechcrafterLastSelfAssessment:
        return {
          notificationText:
            '💭 Reflect on your growth by reviewing your self-assessments',
          redirectRoute: `${getPlatformName()}/self-assessments/assessment-results`,
        };

      case NOTIFICATION_TYPES.speechcraftEventEnd:
        return {
          notificationText: "❗ It's time to close your Speechcraft event!",
          redirectRoute: '',
          hasOpenAction: false,
        };

      case NOTIFICATION_TYPES.reverse_completion_project:
        return {
          notificationText: `Your ${data.pathName} ${data.projectName} project completion has been reversed. Have questions or need assistance? Email&nbsp;<a href="mailto:educationprogram@toastmasters.org">educationprogram@toastmasters.org</a>.`,
          redirectRoute: '',
          hasOpenAction: false,
        };

      case NOTIFICATION_TYPES.reverse_completion_level:
        return {
          notificationText: `Your ${data.pathName} Level ${data.levelNumber} completion has been reversed. Have questions or need assistance? Email&nbsp;<a href="mailto:educationprogram@toastmasters.org">educationprogram@toastmasters.org</a>.`,
          redirectRoute: '',
          hasOpenAction: false,
        };

      case NOTIFICATION_TYPES.reverse_completion_path:
        return {
          notificationText: `Your ${data.pathName} path completion has been reversed. Have questions or need assistance? Email&nbsp;<a href="mailto:educationprogram@toastmasters.org">educationprogram@toastmasters.org</a>.`,
          redirectRoute: '',
          hasOpenAction: false,
        };

      default:
        return { notificationText: '', redirectRoute: `` };
    }
  };

  const {
    notificationText,
    redirectRoute,
    isSenderReceiver = false,
    isDeleted = false,
    hasOpenAction = true,
  } = getNotificationText();

  const shouldRedirectToToastmasterWebsite =
    requestType?.toLocaleLowerCase() === 'path' &&
    status === APPROVAL_REQUEST_STATUS.Approved &&
    type === NOTIFICATION_TYPES.completionReview;

  const displayName = getNotificationSenderName(
    notification.sender,
    type,
    requestType,
    isApprovedByAdmin,
  );

  return {
    status,
    notificationText,
    redirectRoute,
    notificationType: type,
    isSenderReceiver,
    shouldRedirectToToastmasterWebsite,
    displayName,
    isDeleted,
    hasOpenAction,
  };
};
