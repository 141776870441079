import { FunctionComponent } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { get, isEmpty, slice } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import SectionHeader from 'components/SectionHeader';
import CardSlider from 'components/CardSlider';
import CardItem from 'pages/Dashboard/components/Resources/ResourcesDetails/CardItem';
import { ResourcesWrapper } from './style';
import Loader from 'elements/Loader';

const ResourceSection: FunctionComponent<any> = ({
  category,
  handleOpenAssetPreview,
  handleFavoriteAsset,
  disableFavorite,
  handleShareAsset,
  handleClickViewAll,
  resetCategory,
  getNextData,
}) => {
  const { name, id: categoryId, resources } = category;

  const hasResources = !isEmpty(get(resources, 'results', []));

  if (!hasResources && !resetCategory) {
    return <></>;
  }

  return (
    <ResourcesWrapper>
      <SectionHeader
        title={name}
        buttonText={resetCategory ? 'View Less' : 'View All'}
        handleActionButton={() =>
          handleClickViewAll(resetCategory ? '' : categoryId, name)
        }
      />
      <>
        {!hasResources && resetCategory ? (
          <Stack direction='row' justifyContent='center' alignItems='center'>
            No record found
          </Stack>
        ) : resetCategory ? (
          <Box>
            <InfiniteScroll
              dataLength={get(resources, 'results', []).length}
              next={() => getNextData(resources)}
              hasMore={get(resources, 'next', '') ? true : false}
              loader={
                <div className='loader' key={0}>
                  <Loader />
                </div>
              }
              scrollableTarget='scrollableDiv'
              style={{ overflow: 'hidden' }}
            >
              <Box sx={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
                <Grid container spacing={3}>
                {get(resources, 'results', []).map((resource: any, index: any) => (
                    <Grid item xs={12} sm={4} md={3} key={index}>
                      <CardItem
                        data={resource}
                        handleOpenAssetPreview={handleOpenAssetPreview}
                        handleFavoriteAsset={handleFavoriteAsset}
                        disableFavorite={disableFavorite}
                        categoryId={categoryId}
                        handleShareAsset={handleShareAsset}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </InfiniteScroll>
          </Box>
        ) : (
          <CardSlider>
            {slice(get(resources, 'results', []), 0, 20).map(
              (resource: any, index: any) => (
                <CardItem
                  data={resource}
                  key={index}
                  handleOpenAssetPreview={handleOpenAssetPreview}
                  handleFavoriteAsset={handleFavoriteAsset}
                  disableFavorite={disableFavorite}
                  categoryId={categoryId}
                  handleShareAsset={handleShareAsset}
                />
              ),
            )}
          </CardSlider>
        )}
      </>
    </ResourcesWrapper>
  );
};

export default ResourceSection;
