import { memo, FunctionComponent, useState } from 'react';
import {
  Box,
  ModalHeading,
  CloseIconHolder,
  Dialog,
} from 'elements/Modal/style';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';


const ModalComponent: FunctionComponent<any> = props => {
  const { showCloseIcon = true, closeOnOutsideClick = true } = props;

  return (
    <Dialog
      open={true}
      scroll={'paper'}
      onClose={closeOnOutsideClick && props.handleClose}
      className={props.className}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <ModalHeading
        className={props.title ? 'withTitle' : 'withOutTitle'}
        {...({ component: 'div' } as any)}
      >
        <Typography variant='h2' className={props.headingClass}>
          {props.title}
        </Typography>
        {showCloseIcon && (
          <CloseIconHolder
            aria-label='Close'
            className={
              props.title ? 'withTitleCloseIcon' : 'withOutTitleCloseIcon'
            }
            onClick={props.handleClose}
          >
            <CloseIcon />
          </CloseIconHolder>
        )}
      </ModalHeading>
      <Box>{props.children}</Box>
    </Dialog>
  );
};

/**
 *
 * @param Component
 * @returns
 * import HOC from "src/elements/Modal"
 * const ModalManageCat = HOC(ManageCategory)
 * <ModalComponent></ModalComponent>
 */

export const HOC = (Component: any) => {
  return (props: any) => (
    <ModalComponent {...props}>
      <Component {...props} />
    </ModalComponent>
  );
};

export default memo(ModalComponent);
