import { useMemo, useState } from 'react';
import { Box, Chip, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';

import { InfoSection, HeadingWrapper } from './style';
import Loader from 'elements/Loader';
import { ReactComponent as MeetingIcon } from 'assets/images/meetingIcon.svg';
import {
  useGetMeetingRolesQuery,
  useGetMeetingLogsQuery,
} from 'api/profileApi';
import { HOC } from 'elements/Modal';
import CreateMeetingLog from './CreateMeetingLog';
import DeleteMeetingLog from './DeleteMeetingLog';
import useProfilePage from '../useProfilePage';

const CreateMeetingLogModal = HOC(CreateMeetingLog);
const DeleteMeetingLogModal = HOC(DeleteMeetingLog);

const MeetingLogs = () => {
  const { isSelfProfilePage, username } = useProfilePage();

  const [isMeetingLogModalOpen, setIsMeetingLogModalOpen] = useState(false);
  const [isMeetingLogDeleteModalOpen, setIsMeetingLogDeleteModalOpen] =
    useState(false);
  const [selectedLog, setSelectedLog] = useState<any>({});

  const {
    data: meetingRoles = [],
    isFetching: isFetchingRoles,
    isSuccess: isSuccessRoles,
  } = useGetMeetingRolesQuery({});
  const {
    data: meetingLogs = [],
    isFetching: isFetchingLogs,
    isSuccess: isSuccessLogs,
  } = useGetMeetingLogsQuery(isSelfProfilePage ? '' : username);

  const isFetching = isFetchingLogs || isFetchingRoles;

  const userMeetingLogs = useMemo(() => {
    const logs = new Map();
    for (const item of meetingRoles) {
      logs.set(item.id, { name: item.name, userLogs: [] });
    }

    for (const item of meetingLogs) {
      logs
        .get(item.meetingRole)
        ?.userLogs.push({ id: item.id, date: item.date });
    }

    return Array.from(logs);
  }, [meetingLogs, meetingRoles]);

  const handleOpenDeleteLogModal = (deletionLog: any) => {
    setSelectedLog(deletionLog);
    setIsMeetingLogDeleteModalOpen(true);
  };

  return (
    <InfoSection>
      <HeadingWrapper
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 2.5,
          position: 'relative',
        }}
      >
        {isSelfProfilePage ? (
          <Box position='absolute' sx={{ right: -8, top: -8 }}>
            <IconButton
              aria-label='create'
              color='secondary'
              sx={{ svg: { marginRight: 0 } }}
              onClick={() => setIsMeetingLogModalOpen(true)}
            >
              <CreateIcon />
            </IconButton>
          </Box>
        ) : null}
        <Stack direction='row'>
          <MeetingIcon />
          <Typography variant='h3' component='span' m={0}>
            Meeting Roles
          </Typography>
        </Stack>
        <Typography variant='subtitle1' component='span' m={0} lineHeight={1.5}>
          {isSelfProfilePage
            ? 'A self-added log of all meeting roles served. To add, click on the pencil icon at the top right.'
            : 'A self-added log of all meeting roles served.'}
        </Typography>
      </HeadingWrapper>
      {isFetching ? <Loader /> : null}
      {!isFetching && isSuccessRoles && isSuccessLogs ? (
        <Box>
          {userMeetingLogs.map(([key, value]) => (
            <Stack key={key} direction='row' mb={5} gap={4}>
              <Typography variant='h4' lineHeight={1.5} whiteSpace='pre'>
                {value.name}
              </Typography>

              <Stack direction='row' flexWrap='wrap' gap={2}>
                {value.userLogs.map(({ id, date }: any) => {
                  if (isSelfProfilePage) {
                    return (
                      <Chip
                        key={id}
                        label={new Date(date).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                        size='small'
                        deleteIcon={<CloseIcon />}
                        onDelete={() =>
                          handleOpenDeleteLogModal({
                            id,
                            date,
                            name: value.name,
                          })
                        }
                      />
                    );
                  } else {
                    return (
                      <Chip
                        key={id}
                        label={new Date(date).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                        size='small'
                      />
                    );
                  }
                })}
              </Stack>
            </Stack>
          ))}
        </Box>
      ) : null}

      {isMeetingLogModalOpen ? (
        <CreateMeetingLogModal
          title='Add Meeting Role'
          handleClose={() => setIsMeetingLogModalOpen(false)}
          meetingRoles={meetingRoles}
          isLoadingMeetingRoles={isFetchingRoles}
        />
      ) : null}

      {isMeetingLogDeleteModalOpen ? (
        <DeleteMeetingLogModal
          title='Delete Meeting Role'
          handleClose={() => setIsMeetingLogDeleteModalOpen(false)}
          deletionLog={selectedLog}
        />
      ) : null}
    </InfoSection>
  );
};

export default MeetingLogs;
