import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { updateResource } from 'pages/Dashboard/ducks/resourcesSlice';

export const useResourceAssets = () => {
  const dispatch = useDispatch<any>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<any>({});
  const [disableFavorite, setDisableFavorite] = useState({});

  const handleOpenAssetPreview = (asset: any) => {
    setSelectedAsset(asset);
    setIsModalOpen(true);
    dispatch(
      updateResource({
        payload: { lastViewed: new Date() },
        assetId: asset.id,
      }),
    );
  };

  const handleCloseAssetPreview = () => {
    setSelectedAsset({});
    setIsModalOpen(false);
  };

  const handleFavoriteAsset = async (
    assetId: any,
    favorite: any,
    resourceId: any,
    categoryId: any,
  ) => {
    setDisableFavorite(prev => ({ ...prev, [assetId]: true }));

    try {
      await dispatch(
        updateResource({
          categoryId,
          assetId,
          payload: { favorite },
          callback: (payload: any) => {
            setDisableFavorite(prev => ({ ...prev, [assetId]: false }));
            setSelectedAsset((prev: any) => ({
              ...prev,
              favorite: payload.favorite,
            }));
          },
        }),
      ).unwrap();
    } catch {}
  };

  return {
    isModalOpen,
    selectedAsset,
    disableFavorite,
    handleOpenAssetPreview,
    handleCloseAssetPreview,
    handleFavoriteAsset,
  };
};
