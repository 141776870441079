import { FC } from 'react';
import { Typography } from '@mui/material';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';

import { getUserName } from 'utils/utility';
import { GreetUser } from 'pages/Dashboard/style';
import { useGetProfileDetailQuery } from 'api/profileApi';

const GreetingUser: FC<any> = () => {
  const user = getAuthenticatedUser();

  const { data: userData, isSuccess: userDataSuccess } =
    useGetProfileDetailQuery(user);

  return (
    <>
      {userDataSuccess && (
        <Typography variant='h2'>Base Camp Manager Dashboard</Typography>
      )}
    </>
  );
};

export default GreetingUser;
