import { Box, Stack, Typography, Tooltip } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

import { BADGE_TYPES, LMS_BASE_URL } from 'config';
import StyledBadge from 'components/Badge';
import { BadgeSharingWrapper, BadgeElement } from 'pages/Dashboard/components/Achievements/style';
import CopyToClipboard from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';
import TextField from 'elements/Form/TextField';
import Button from 'elements/Button';
import { ReactComponent as TwitterXIcon } from 'assets/images/icons/TwitterX.svg';

const commonShareButtonStyles = {
  display: 'inline-flex',
  alignItems: 'center',
  gap: '0.625rem',
  width: '8.125rem',
  borderRadius: 8,
};

const iconSize = 40;
const commonIconStyles = {
  borderRadius: 8,
};

const CopyToClipboardButton = ({ onCopy, sharingUrl, copied }: any) => (
  <CopyToClipboard onCopy={onCopy} text={sharingUrl}>
    <Tooltip title={copied ? 'Copied to clibboard!' : 'Copy'} arrow>
      <Button variant='contained' className='btn-share' onClick={onCopy}>
        {copied ? 'Copied URL' : 'Copy URL'}
      </Button>
    </Tooltip>
  </CopyToClipboard>
);

const BadgeSharing: FC<any> = ({ badge }) => {
  const {
    badgeMeta,
    badgeEarnCount,
    badgeType,
    earnedBadgeId,
  } = badge;

  const isLearnerBadge = badgeType !== BADGE_TYPES.speechFeedbackBadge && badgeType !== BADGE_TYPES.learnerFeedbackBadge;

  const sharingUrl = `${LMS_BASE_URL}/public/social/${earnedBadgeId}/`;
  const [copied, setCopied] = useState(false);
  const onCopy = useCallback(() => {
    setCopied(true);
    navigator.clipboard.writeText(sharingUrl)
  }, [sharingUrl])

  return (
    <BadgeSharingWrapper>
      <Stack alignItems='center'>
        <BadgeElement className='cursor-auto'>
          <StyledBadge
            className={isLearnerBadge ? 'learner-badge-holder' : ''}
            badgeImage={badgeMeta.image}
            altText={badgeMeta.name}
            badgeContent={badgeEarnCount}
            isDisabled={true}
          />
          <Typography>{badgeMeta.name}</Typography>
        </BadgeElement>
      </Stack>

      <Typography
        textAlign='center'
        variant='h4'
        color='ternary.dark'
        fontWeight={500}
      >
        {badgeMeta.description}
      </Typography>

      <Typography textAlign='center' mt={10} fontWeight={600}>
        Share on
      </Typography>

      <Stack
        direction='row'
        gap={2.5}
        alignItems='center'
        justifyContent='center'
        flexWrap="wrap"
        mt={2.5}
      >
      <LinkedinShareButton
        style={{ ...commonShareButtonStyles, backgroundColor: '#026CA9' }}
        url={sharingUrl}
        title={badgeMeta.name}
        summary={badgeMeta.socialMediaDescription}
      >
        <LinkedinIcon size={iconSize} style={commonIconStyles} />
        <Typography variant='h5' color='white'>
          Linkedin
        </Typography>
      </LinkedinShareButton>

      {/* <TwitterShareButton
        style={{ ...commonShareButtonStyles, backgroundColor: '#1191C9' }}
        url={sharingUrl}
        title={`${badgeMeta.name} | ${badgeMeta.socialMediaDescription}`}
      >
        <TwitterIcon size={iconSize} style={commonIconStyles} />
        <Typography variant='h5' color='white'>
          Twitter
        </Typography>
      </TwitterShareButton> */}

      <FacebookShareButton
        style={{ ...commonShareButtonStyles, backgroundColor: '#2F4D8B' }}
        url={sharingUrl}
        quote={badgeMeta.socialMediaDescription} // facebook does not support this anymore
      >
        <FacebookIcon size={iconSize} style={commonIconStyles} />
        <Typography variant='h5' color='white'>
          Facebook
        </Typography>
      </FacebookShareButton>

      

        <div className="social-link-holder">
          <FormControlLabel
            label=''
            labelPlacement='top'
            control={
              <TextField
                fullWidth
                className='has-data'
                disabled={true}
                id='selectClub'
                value={sharingUrl}
                readOnly
              />
            }
          />
          <CopyToClipboardButton
            onCopy={onCopy}
            text={sharingUrl}
            copied={copied}
          />
        </div>
      </Stack>
    </BadgeSharingWrapper>
  );
};

export default BadgeSharing;
