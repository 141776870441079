import { FC, useEffect, useState } from 'react';
import { Box, RadioGroup, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';

import { TextField } from 'elements/Form/style';
import CheckboxesAutoComplete from 'elements/AutoComplete/CheckboxesAutoComplete';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';
import Button from 'elements/Button';
import { Radio } from 'elements/Radio/style';
import { FieldWrapper } from 'elements/Form/style';
import ConfirmIcon from 'elements/Svgs/Confirm';
import { useGetEventAttendeesQuery } from 'api/speechCraftApi';
import { useAppContext } from 'contexts/AppContext';
import { useIsSpeechCraftMFE, useValidations } from 'hooks';
import { getUserName } from 'utils/utility';

import Modal from 'elements/Modal';
import {
  useGetClubsQuery,
  useGetClubMembersQuery,
  useRequestFeedbackMutation,
} from '../feedbackApi';

// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';
import { ImageWrapper } from './style';
import trim from 'lodash/trim';
import size from 'lodash/size';

import { useFormik } from 'formik';
import { getValidationSchema } from './validationSchema';
import DropdownField from 'elements/DropdownField';

const INITIAL_VALUES = {
  clubId: '',
  clubMembers: [],
  request: '',
  visibility: 'public',
};

const RequestFeedback: FC<any> = props => {
  const user = getAuthenticatedUser();
  const { event } = useAppContext();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();

  const {
    modalOpen,
    handleClose,
    handleOpen,
    selectedClub,
    selectedClubName,
    selectedMembers,
    slectedEvent,
  } = props;

  const [initialValues, setInitialValues] = useState<any>(INITIAL_VALUES);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [club, setClub] = useState('');

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: getValidationSchema(isSpeechCraftMFE, true),
    onSubmit: () => {
      handleOpenConfirmation();
    },
  });

  const {
    setFieldValue,
    values: formikValues,
    setFieldTouched,
    touched,
    errors,
  }: any = formik;

  const handleCloseConfirmation = () => {
    setConfirmationModalOpen(false);
    handleClose();
    resetState();
  };

  const handleOpenConfirmation = () => {
    handleClose();
    setConfirmationModalOpen(true);
  };

  const handleFormSubmitted = () => {
    handleClose();
    setConfirmationModalOpen(false);
  };

  const handleGoBack = () => {
    setConfirmationModalOpen(false);
    handleOpen();
  };

  const resetState = () => {
    setClub(selectedClub ?? '');
    handleFormSubmitted();
    formik.resetForm();
  };

  const handleUpdateField = (event: any) => {
    const { name, value } = event.target;
    const isRequestField = name === 'request';
    const updatedValue = size(trim(value)) > 0 ? value : '';

    setFieldValue(name, isRequestField ? updatedValue : value);
    setFieldTouched(name, true, false);

    if (name === 'clubId') {
      setClub(value);
      setFieldValue('clubMembers', [], false);
      setFieldTouched(name, false, false);
    }
  };

  const {
    data: clubs,
    isFetching: isFetchingClubs,
    isSuccess: isSuccessClubs,
  } = useGetClubsQuery({}, { skip: selectedClub || isSpeechCraftMFE });

  const {
    data: eventMembersData,
    isFetching: isFetchingEventMembers,
    isSuccess: isSuccessEventMembers,
  } = useGetEventAttendeesQuery(
    { attendeesType: 'all' },
    { skip: !isSpeechCraftMFE },
  );

  const { attendees = [] } = eventMembersData || {};

  useEffect(() => {
    if (clubs?.length === 1) {
      setInitialValues((prev: any) => ({
        ...prev,
        clubId: clubs[0].id,
        clubMembers: [],
      }));
      setClub(clubs[0].id);
    }
  }, [clubs]);

  useEffect(() => {
    if (!selectedClub && !slectedEvent) {
      return;
    }

    if (selectedClub) {
      setClub(selectedClub);
    }

    setInitialValues((prev: any) => ({
      ...prev,
      clubMembers: selectedMembers,
      ...(selectedClub && { clubId: selectedClub }),
    }));
  }, [selectedClub, slectedEvent, selectedMembers]);

  const {
    data: clubMembers = [],
    isFetching: isFetchingMembers,
    isSuccess: isSuccessMembers,
  } = useGetClubMembersQuery(
    { clubId: club },
    { skip: club === '' || selectedClub || isSpeechCraftMFE },
  );

  const [requestFeedback, { isLoading }] = useRequestFeedbackMutation();

  const handleRequestFeedback = async () => {
    const { clubId, clubMembers, request, visibility } = formikValues;
    const membersIds = clubMembers.map(({ id }: Record<string, string>) => id);

    const data = {
      ...(isSpeechCraftMFE ? { event: event.id } : { club: clubId }),
      receiver: membersIds,
      isPrivate: visibility === 'private',
      sender: user.userId,
      request,
    };

    try {
      await requestFeedback(data).unwrap();
      toast.success('Your feedback request has been successfully sent');
      resetState();
    } catch (error: any) {
      toast.error(error?.data || 'Something went wrong!');
    }
  };

  const clubOptions = clubs?.reduce(
    (acc: any, { name, id }: any) => [
      ...acc,
      {
        label: name,
        value: id,
      },
    ],
    [],
  );

  const membersOptions = isSpeechCraftMFE
    ? attendees?.map((user: any) => ({
        id: user.id,
        name: getUserName(user),
        firstName: getUserName(user, true),
      }))
    : clubMembers
        .filter((cm: any) => cm.id !== user.userId)
        .map((cm: any) => ({
          ...cm,
          name: getUserName(cm),
        }));

  useValidations(formik);

  return (
    <>
      {modalOpen ? (
        <Modal title='Request Feedback' handleClose={handleCloseConfirmation}>
          <Box mt={1.5}>
            {(selectedClub && selectedClubName) || isSpeechCraftMFE ? (
              <FieldWrapper mb={4}>
                <FormControlLabel
                  label={isSpeechCraftMFE ? 'Event' : 'Club'}
                  labelPlacement='top'
                  control={
                    <TextField
                      fullWidth
                      className='has-data'
                      disabled={true}
                      id='selectClub'
                      value={isSpeechCraftMFE ? event?.name : selectedClubName}
                    />
                  }
                />
              </FieldWrapper>
            ) : null}

            {selectedClub || slectedEvent ? (
              <FieldWrapper mb={4}>
                <FormControlLabel
                  label='Member'
                  labelPlacement='top'
                  control={
                    <TextField
                      fullWidth
                      className='has-data'
                      disabled={true}
                      id='selectMember'
                      value={getUserName(formikValues.clubMembers[0])}
                    />
                  }
                />
              </FieldWrapper>
            ) : null}

            {!selectedClub && !isSpeechCraftMFE && !slectedEvent ? (
              <FieldWrapper mb={4}>
                <FormControlLabel
                  label='Club'
                  labelPlacement='top'
                  control={
                    <DropdownField
                      options={clubOptions}
                      className={`h-50 ${
                        formikValues.clubId ? 'has-data' : ''
                      }`}
                      value={formikValues.clubId}
                      name='clubId'
                      helperText={touched['clubId'] && errors['clubId']}
                      error={!!touched['clubId'] && !!errors['clubId']}
                      handleChange={handleUpdateField}
                      isLoading={isFetchingClubs}
                      placeholder='Select a club'
                    />
                  }
                />
              </FieldWrapper>
            ) : null}

            {!selectedClub && !slectedEvent && (
              <FieldWrapper mb={4}>
                <FormControlLabel
                  label={isSpeechCraftMFE ? 'Recipient' : 'Member(s)'}
                  labelPlacement='top'
                  control={
                    <CheckboxesAutoComplete
                      multiple
                      id='select-club-members'
                      options={membersOptions}
                      disableCloseOnSelect
                      loading={
                        isSpeechCraftMFE
                          ? isFetchingEventMembers
                          : isFetchingMembers
                      }
                      loadingText={
                        isSpeechCraftMFE
                          ? 'Loading Recipient(s)'
                          : 'Loading Members'
                      }
                      getOptionLabel={(option: any) => option.name}
                      defaultValue={formikValues.clubMembers}
                      selectedOptions={(members: any) => {
                        setFieldValue('clubMembers', members);
                        setFieldTouched('clubMembers', true, false);
                      }}
                      placeholder={`Select ${
                        isSpeechCraftMFE ? 'recipient' : 'member'
                      }(s)`}
                      name='clubMembers'
                      helperText={
                        touched['clubMembers'] && errors['clubMembers']
                      }
                      error={
                        !!touched['clubMembers'] && !!errors['clubMembers']
                      }
                    />
                  }
                />
              </FieldWrapper>
            )}

            <FieldWrapper mb={4}>
              <FormControlLabel
                label='Request'
                labelPlacement='top'
                control={
                  <TextField
                    multiline
                    fullWidth
                    name='request'
                    className={`${
                      formikValues.request
                        ? 'has-data textarea-italic'
                        : ' textarea-italic'
                    }`}
                    id='feedback-request'
                    placeholder={
                      isSpeechCraftMFE
                        ? 'Example: Please provide feedback on my recent speech.'
                        : 'For example: Please provide feedback about my audience awareness in my most recent speech.'
                    }
                    value={formikValues.request}
                    onChange={handleUpdateField}
                    helperText={touched['request'] && errors['request']}
                    error={!!touched['request'] && !!errors['request']}
                  />
                }
              />
            </FieldWrapper>
          </Box>

          <Box mt={15}>
            <Typography variant='h4'>Visibility</Typography>
            <Stack direction='column' gap={8}>
              <RadioGroup
                aria-labelledby='request-visibility-controlled-radio-buttons-group'
                name='visibility'
                value={formikValues.visibility}
                onChange={e => handleUpdateField(e)}
              >
                <FormControlLabel
                  value='public'
                  control={<Radio size='small' />}
                  label={`Visible to all ${
                    isSpeechCraftMFE ? 'members of my event' : 'club members'
                  }`}
                />
                <FormControlLabel
                  value='private'
                  control={<Radio size='small' />}
                  label={`Visible to selected ${
                    isSpeechCraftMFE ? 'recipient(s)' : 'member(s)'
                  } only`}
                />
              </RadioGroup>

              <Button
                className='alignSelfEnd'
                variant='contained'
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Send request
              </Button>
            </Stack>
          </Box>
        </Modal>
      ) : null}

      {confirmationModalOpen ? (
        <Modal title='Confirmation' handleClose={handleCloseConfirmation}>
          <ImageWrapper>
            <ConfirmIcon />
          </ImageWrapper>
          <Typography textAlign='center' variant='h3' fontWeight={400}>
            {`Are you sure you want to 
            ${
              isSpeechCraftMFE
                ? 'request feedback from'
                : 'send this feedback request to'
            }`}{' '}
            {[
              formikValues.clubMembers
                .map((m: any) => getUserName(m, true))
                .slice(0, -1)
                .join(', '),
              formikValues.clubMembers
                .map((m: any) => getUserName(m, true))
                .slice(-1)[0],
            ].join(
              formikValues.clubMembers.length === 1
                ? ''
                : formikValues.clubMembers.length < 3
                ? ' and '
                : ', and ',
            )}
            ?
          </Typography>

          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            gap={2.5}
            mt={15}
          >
            <Button
              variant='outlined'
              onClick={handleGoBack}
              disabled={isLoading}
            >
              Back
            </Button>
            <Button
              variant='contained'
              onClick={handleRequestFeedback}
              disabled={isLoading}
            >
              Send
            </Button>
          </Stack>
        </Modal>
      ) : null}
    </>
  );
};

export default RequestFeedback;
