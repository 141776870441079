import React, { memo, FunctionComponent } from 'react';
import { Typography, Box } from '@mui/material';

import Button from 'elements/Button';

const SectionHeader: FunctionComponent<any> = ({
  title,
  buttonVariant,
  buttonText,
  buttonColor,
  handleActionButton,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant='h3' className='fw-600' mb={4}> {title} </Typography>
      {buttonText && (
        <Button onClick={handleActionButton} variant={buttonVariant} color={buttonColor ? buttonColor : "secondary"}>
          {buttonText}
        </Button>
      )}
    </Box>
  );
};

SectionHeader.defaultProps = {
  buttonVariant: 'text',
  handleActionButton: () => {},
};

export default memo(SectionHeader);
